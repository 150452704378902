import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
    bloodGroupOptions,
    currencyOptions,
    datePickerFormat,
    employeeStatusOptions,
    ethnicityTypeOptions,
    gendersTypeOptions,
    jobDesignationOptions,
    jobLevelOptions,
    jobShiftOptions,
    jobTypeOptions,
    languagesOptions,
    maritalStatusOptions,
    relationshipOptions,
    uploadedTypesOptions,
    userTypesOptions
} from '@app/core/constants/options.constants';
import { User } from '@app/core/models';
import { CandidateService, JobService, UserService, UtilitiesService } from '@app/core/services';
import { EmployeesService } from '@app/core/services/employees.service';
import { FormHelperService } from '@app/core/services/form-helper.service';
import { TenantService } from '@app/core/services/tenant.service';
import { WorkflowService } from '@app/core/services/workflow.service';
import { CompensationValidation } from '@app/core/validators/compensation.validator';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { SelectItem } from 'primeng';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import * as fromStore from './../../../../../store';
import * as fromSelectors from './../../../../../store/selectors';
import { UniversalBankCodes } from '@app/core/constants/cfao-offer.constants';

@Component({
    selector: 'app-employee-item-edit',
    templateUrl: './employee-item-edit.component.html',
    styleUrls: ['./employee-item-edit.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: datePickerFormat }
    ]
})
export class EmployeeItemEditComponent implements OnInit, OnDestroy {
    @Input() employee;
    @Output() documentUpdated: EventEmitter<any> = new EventEmitter();
    @Output() employmentSectionUpdated: EventEmitter<any> = new EventEmitter();

    initialRole;
    isFormLoading = false;
    activeSection = 'job';
    jobForm: FormGroup;
    personalForm: FormGroup;
    compensationForm: FormGroup;
    timeOffForm: FormGroup;
    cv: FormGroup;
    formsCopy: any = {};
    initialCountry: string = 'za';
    file: any;
    uploadError: string;
    usersSubscription: Subscription;
    users: User[];
    user: User;
    documents = [];
    birthMaxValue = new Date();
    @ViewChild('image', { static: false }) image: ElementRef;

    showUploaderOptions = false;
    selectedDocumentType = null;
    selectedDocumentCategory = null;
    resumeUploadDisabled = false;

    uploadValidationRules = {
        resume: 5,
        letter: 5,
        portfolio: 20,
        id: 5,
        other: 20
    };
    uploadQueue: any[] = [];
    supportedFileTypes: string[];

    contentLoading = false;

    employeeStatusOptions: SelectItem[] = employeeStatusOptions;
    jobTypeOptions: SelectItem[] = jobTypeOptions;
    jobDepartmentOptions: SelectItem[] = [];
    jobDesignationOptions: SelectItem[] = jobDesignationOptions;
    jobBusinessUnitOptions: SelectItem[] = [];
    jobLocationOptions: SelectItem[] = [];
    jobPrimaryTeamOptions: SelectItem[] = [{ label: '1', value: '1' }];
    jobSubDepartmentOptions: SelectItem[] = [{ label: '1', value: '1' }];
    jobLevelOptions: SelectItem[] = jobLevelOptions;
    jobCostCentrOptions: SelectItem[] = [{ label: '1', value: '1' }];
    jobShiftOptions: SelectItem[] = jobShiftOptions;
    userTypesOptions: SelectItem[] = userTypesOptions;
    uploadedTypesOptions: any[] = uploadedTypesOptions;
    contactsOptions = [];
    relationshipOptions = relationshipOptions;
    membershipOptions = [
        { value: 'parent', label: 'parent' },
        { value: 'father', label: 'father' },
        { value: 'mother', label: 'mother' },
        { value: 'spouse', label: 'spouse' },
        { value: 'husband', label: 'husband' },
        { value: 'wife', label: 'wife' },
        { value: 'child', label: 'child' },
        { value: 'son', label: 'son' },
        { value: 'daughter', label: 'daughter' },
        { value: 'sibling', label: 'sibling' },
        { value: 'brother', label: 'brother' },
        { value: 'sister', label: 'sister' },
        { value: 'friend', label: 'friend' },
        { value: 'partner', label: 'partner' }
    ];
    ethnicityOptions: SelectItem[] = ethnicityTypeOptions;
    gendersOptions: SelectItem[] = gendersTypeOptions;
    bloodGroupOptions: SelectItem[] = bloodGroupOptions;
    maritalStatusOptions: SelectItem[] = maritalStatusOptions;
    currencyOptions: SelectItem[] = currencyOptions;
    reasonOptions = [
        { label: 'New Employee', value: 'New Employee' },
        { label: 'Annual Appraisal', value: 'Annual Appraisal' },
        { label: 'Mid Year Appraisal', value: 'Mid Year Appraisal' },
        { label: 'Merit', value: 'Merit' },
        { label: 'Adjustment', value: 'Adjustment' },
        { label: 'Adjustment to Grade Minimum', value: 'Adjustment to Grade Minimum' },
        { label: 'Certification', value: 'Certification' },
        { label: 'Completed Probation Period', value: 'Completed Probation Period' },
        { label: 'Contract', value: 'Contract' },
        { label: 'Conversion', value: 'Conversion' },
        { label: 'Education Progression', value: 'Education Progression' },
        { label: 'Equity Pay Adjustment', value: 'Equity Pay Adjustment' },
        { label: 'Market Adjustment', value: 'Market Adjustment' },
        { label: 'Salary Change Due To Reorganisation', value: 'Salary Change Due To Reorganisation' },
        { label: 'Step Progression', value: 'Step Progression' }
    ];
    bonusTypeOptions = [
        { label: '13th Cheque', value: '13th cheque', selected: false },
        { label: 'Performance Bonus', value: 'performance bonus', selected: false }
    ];
    allowancesTypeOptions = [
        { label: 'Mobile Phone', value: 'mobile phone', selected: false },
        { label: 'Petrol Card', value: 'petrol', selected: false },
        { label: 'Petrol Allowance', value: 'petrol allowance', selected: false },
        { label: 'Motor Vehicle Allowance', value: 'motor vehicle allowance', selected: false },
        { label: 'Funeral Cover', value: 'funeral cover', selected: false }
    ];

    timeOffTypeOptions = [
        { label: 'Earned', value: 'earned', selected: false },
        { label: 'Sick', value: 'sick', selected: false }
    ];

    payScheduleOptions = [
        { value: 'hourly', label: 'Hourly' },
        { value: 'daily', label: 'Daily' },
        { value: 'weekly', label: 'Weekly' },
        { value: 'monthly', label: 'Monthly' },
        { value: 'yearly', label: 'Yearly' }
    ];
    bankTypeOptions = [
        { label: 'ABSA Bank', value: 'ABSA Bank', branch_code: 632005 },
        { label: 'Capitec Bank', value: 'Capitec Bank', branch_code: 470010 },
        { label: 'Discovery Bank', value: 'Discovery Bank', branch_code: 679000 },
        { label: 'FNB / RMB', value: 'FNB / RMB', branch_code: 250655 },
        { label: 'Investec Bank', value: 'Investec Bank', branch_code: 580105 },
        { label: 'Nedbank Limited', value: 'Nedbank Limited', branch_code: 198765 },
        { label: 'Standard Bank', value: 'Standard Bank', branch_code: '051001' }
    ];
    languagesOptions = languagesOptions;

    countriesOptions: SelectItem[] = [];
    reportInvalid = [];
    dependentsInvalid = [];
    salaryInvalid = [];
    bonusesInvalid = [];
    allowancesInvalid = [];
    bursariesInvalid = [];
    timeOffInvalid = [];
    emergencyContactsInvalid = [];
    dropdownValueUpdated = false;
    @Output() close: EventEmitter<any> = new EventEmitter();
    terms$ = new Subject<string>();
    secureNavLinksVisible = false;
    userSubscription: Subscription;

    qualificationLengthOptions: any[] = [];
    currentYearOptions: any[] = [];
    accountOwnershipTypeOptions = [
        { value: 'O', label: 'Own' },
        { value: 'J', label: 'Joint' },
        { value: 'T', label: 'Third Party' }
    ];
    accountTypeOptions = [
        { value: 'CA', label: 'Cheque' },
        { value: 'SA', label: 'Savings' },
        { value: 'TA', label: 'Transmission' },
        { value: 'LA', label: 'Loan Account' }
    ];
    constructor(
        private fb: FormBuilder,
        private employeeService: EmployeesService,
        public utilitiesService: UtilitiesService,
        private formHelper: FormHelperService,
        private sanitizer: DomSanitizer,
        private workflowService: WorkflowService,
        private jobService: JobService,
        private tenantService: TenantService,
        private store: Store<fromStore.State>,
        private userService: UserService,
        private candidateService: CandidateService,
        private toastr: ToastrService
    ) {
        this.tenantService.getMaritalStatusOptions().subscribe((response) => {
            if (response && response.length) {
                this.maritalStatusOptions = response;
            }
        });

        this.supportedFileTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.oasis.opendocument.text',
            'text/rtf'
        ];
        this.terms$.pipe(debounceTime(600)).subscribe((val) => {
            if (val.trim() !== '') {
                this.checkUserExists();
            }
        });
        for (let i = 1; i < 11; i++) {
            this.qualificationLengthOptions.push({ value: i, label: i.toString() });
        }
    }

    async ngOnInit() {
        this.initialRole = this.employee.role;
        // console.log('Edit employee:', { ...this.employee });
        this.tenantService.getLocations().subscribe((response) => {
            if (response) {
                response = response.filter((l) => l.name !== 'Any of the Dimension Data Offices');
                response.forEach((l) => {
                    this.jobLocationOptions.push({
                        label: `${l.name} - ${l.location_short}`,
                        value: l.location
                    });
                });
            }
        });
        this.userSubscription = this.store.pipe(select(fromSelectors.getUserEntity)).subscribe((user: User) => {
            this.user = user;
            const allowedRoles = ['account_owner', 'admin'];
            if (this.user.role && allowedRoles.includes(this.user.role)) {
                this.secureNavLinksVisible = true;
            }
            if (this.user.tenant_id === 'DR5SALXUV' || this.user.tenant_id === 'JV8E2Q5IO') {
                this.bankTypeOptions = UniversalBankCodes.map((c) => {
                    return {
                        value: c.Bank,
                        label: c.Bank,
                        branch_code: c['Branch Code']
                    };
                });
            }
        });
        this.usersSubscription = this.store.pipe(select(fromSelectors.getUsersEntities)).subscribe((users: User[]) => {
            this.users = [...users];
            if (this.users && this.users.length) {
                console.log('🎩 ALL:', this.users);
                this.contactsOptions = users
                    .sort((a: any, b: any) => {
                        if (a.first_name && b.first_name) {
                            const labelA = a.first_name.toUpperCase();
                            const labelB = b.first_name.toUpperCase();
                            return labelA.localeCompare(labelB);
                        }
                    })
                    .map((u) => {
                        return {
                            label: `${u.first_name} ${u.last_name}`,
                            value: u.id
                        };
                    });
            }
        });
        // this.employeeService.getAllEmployees().subscribe((response: any) => {
        //     console.log('employees', response);
        //     this.users = [...response];
        //     this.contactsOptions = response
        //         .sort((a: any, b: any) => {
        //             if (a.first_name && b.first_name) {
        //                 const labelA = a.first_name.toUpperCase();
        //                 const labelB = b.first_name.toUpperCase();
        //                 return labelA.localeCompare(labelB);
        //             }
        //         })
        //         .map((u) => {
        //             return {
        //                 label: `${u.first_name} ${u.last_name}`,
        //                 value: u.id
        //             };
        //         });
        // });

        const jobDetails = this.employee.job_details ? this.employee.job_details : {};
        this.jobForm = this.fb.group({
            personal: this.fb.group({
                first_name: [this.employee.first_name || '', Validators.required],
                last_name: [this.employee.last_name || '', Validators.required],
                work_email: [this.employee.email, [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
                personal_email: [
                    this.employee.personal_email || '',
                    [Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$')]
                ],
                phone: [
                    this.employee.phone || '',
                    {
                        asyncValidators: [this.userService.phoneIsUniqueForTenantValidator(this.employee.email)]
                    }
                ]
            }),
            employee_id: [this.employee.employee_id || ''],
            status: [this.employee.status || ''],
            start_date: [''],
            type: [jobDetails.type || ''],
            department: [jobDetails.department || ''],
            designation: [jobDetails.designation || ''],
            business_unit: [jobDetails.business_unit || ''],
            job_location: [this.employee.job_location || jobDetails.job_location || jobDetails.location || ''],
            sub_department: [jobDetails.sub_department || ''],
            primary_team: [jobDetails.primary_team || ''],
            additional_teams: [jobDetails.additional_teams || ''],
            level: [jobDetails.level || ''],
            cost_centr: [jobDetails.cost_centr || ''],
            role: [this.employee.role || 'employee'],
            shift: [jobDetails.shift || ''],
            id_card: [jobDetails.id_card || ''],
            probation_start_date: [''],
            probation_end_date: [''],
            previous_company_name: [jobDetails.previous_company_name || ''],
            previous_start_date: [''],
            reporting: this.fb.array([])
        });
        // console.log(this.jobForm.value);
        this.populateJobForm(jobDetails);
        // this.reportInvalid.push(true);

        const personal = this.employee.personal ? this.employee.personal : '';
        this.personalForm = this.fb.group({
            profile_image: [personal.profile_image || ''],
            first_name: [this.employee.first_name, Validators.required],
            last_name: [this.employee.last_name, Validators.required],
            gender: [this.employee.gender || ''],
            date_of_birth: [''],
            ethnicity: [this.employee.ethnicity || ''],
            marital_status: [personal.marital_status],
            blood_group: [personal.blood_group],
            languages: [personal.languages],
            identity_number: [personal.identity_number],
            residential_address: this.fb.group({
                street: [(this.employee.address && this.employee.address.streetAddress) || ''],
                city: [(this.employee.address && this.employee.address.addressLocality) || ''],
                state: [(this.employee.address && this.employee.address.addressRegion) || ''],
                country: [(this.employee.address && this.employee.address.addressCountry) || ''],
                code: [(this.employee.address && this.employee.address.postalCode) || '']
            }),
            postal_address: this.fb.group({
                street: [(personal.postal_address && personal.postal_address.street) || ''],
                city: [(personal.postal_address && personal.postal_address.city) || ''],
                state: [(personal.postal_address && personal.postal_address.state) || ''],
                country: [(personal.postal_address && personal.postal_address.country) || ''],
                code: [(personal.postal_address && personal.postal_address.code) || '']
            }),
            same_as_residential: [personal.same_as_residential || false],
            dependents: this.fb.array([]),
            emergency_contacts: this.fb.array([])
        });
        if (this.employee.personal) {
            this.populatePersonalForm(this.employee.personal);
        }
        this.cv = this.fb.group({
            languages: [],
            summary: [this.employee.summary],
            certificates: this.fb.array([]),
            employment_history: this.fb.array([]),
            education: this.fb.array([])
        });
        let bank_details = this.employee.banking_details ? this.employee.banking_details : {};
        this.compensationForm = this.fb.group({
            salary: this.fb.array([]),
            bonuses: this.fb.array([]),
            allowances: this.fb.array([]),
            bursaries: this.fb.array([]),
            banking_details: this.fb.group({
                bank: [bank_details.bank || ''],
                name: [bank_details.name || ''],
                code: [bank_details.code || ''],
                account_number: [bank_details.account_number || ''],
                match: [bank_details.match ? bank_details.match : false]
            })
        });
        this.compensationForm.get('allowances').valueChanges.subscribe((changes) => {
            this.allowancesTypeOptions.forEach((o) => (o.selected = false));
            changes.forEach((c) => {
                const option = this.allowancesTypeOptions.find((a) => a.value === c.type);
                if (option) {
                    option.selected = true;
                }
            });
        });
        this.compensationForm.get('bonuses').valueChanges.subscribe((changes) => {
            this.bonusTypeOptions.forEach((o) => (o.selected = false));
            changes.forEach((c) => {
                const option = this.bonusTypeOptions.find((a) => a.value === c.type);
                if (option) {
                    option.selected = true;
                }
            });
        });
        this.populateCompensationForm(this.employee);

        this.timeOffForm = this.fb.group({
            time_off: this.fb.array([])
        });
        this.timeOffForm.get('time_off').valueChanges.subscribe((changes) => {
            this.timeOffTypeOptions.forEach((o) => (o.selected = false));
            changes.forEach((c) => {
                const option = this.timeOffTypeOptions.find((a) => a.value === c.type);
                if (option) {
                    option.selected = true;
                }
            });
        });
        this.populateTimeOffFormForm(this.employee);

        this.utilitiesService.getCountries().subscribe((countries: Array<{ name: string; code: string }>) => {
            countries.forEach((c) => {
                this.countriesOptions.push({ label: c.name, value: c.code });
            });
        });

        // if (this.employee.documents) {
        //     this.findUnapproachableDocument(this.employee.documents);
        // }

        const departmentsList = await this.employeeService.getDepartmentsList().toPromise();
        this.jobDepartmentOptions = departmentsList.map((d: string) => ({ label: d, value: d }));
        this.store
            .pipe(
                select(fromSelectors.getBusinessUnits),
                filter((res) => !!res)
            )
            .subscribe(
                (res: any) => {
                    this.jobBusinessUnitOptions = res;
                },
                (errorResponse) => {
                    console.error(errorResponse);
                }
            );
        if (this.user && (this.user.tenant_id === 'DR5SALXUV' || this.user.tenant_id === 'JV8E2Q5IO')) {
            let banking_details = this.employee.banking_details ? this.employee.banking_details : {};
            (this.compensationForm.get('banking_details') as FormGroup).addControl(
                'account_type',
                new FormControl(banking_details.account_type || '', Validators.required)
            );
            (this.compensationForm.get('banking_details') as FormGroup).addControl(
                'account_ownership',
                new FormControl(banking_details.account_ownership || '', Validators.required)
            );
        }
    }

    populateCompensationForm(data) {
        if (!data.salary) {
            (this.compensationForm.get('salary') as FormArray).push(
                this.fb.group({
                    effective_date: ['', Validators.required],
                    end_date: [moment().format('DD-MM-YYYY')],
                    currency: ['', Validators.required],
                    salary: ['', Validators.required],
                    reason: [''],
                    variable_pay: [false]
                })
            );
            this.salaryInvalid.push(true);
        } else {
            data.salary.forEach((item) => {
                const formGroup = this.fb.group({
                    effective_date: [moment(item.effective_date, 'DD-MM-YYYY').format(), Validators.required],
                    end_date: [item.end_date],
                    currency: [item.currency, Validators.required],
                    salary: [item.salary, Validators.required],
                    reason: [item.reason || ''],
                    variable_pay: [item.variable_pay]
                });
                if (item.variable_pay && item.commission) {
                    formGroup.addControl(
                        'commission',
                        this.fb.group(
                            {
                                percentage: [item.commission.percentage],
                                amount: [item.commission.amount],
                                payout_frequency: [item.commission.payout_frequency]
                            },
                            {
                                validator: CompensationValidation.MatchField
                            }
                        )
                    );
                }
                this.salaryInvalid.push(false);
                (this.compensationForm.get('salary') as FormArray).push(formGroup);
            });
        }
        if (data.bonuses) {
            data.bonuses.forEach((item) => {
                const formData = {
                    effective_date: [moment(item.effective_date, 'DD-MM-YYYY').format(), Validators.required],
                    end_date: [item.end_date],
                    currency: [item.currency, Validators.required],
                    amount: [item.amount, Validators.required],
                    type: [item.type]
                };
                this.bonusesInvalid.push(false);
                (this.compensationForm.get('bonuses') as FormArray).push(this.fb.group(formData));
            });
        }
        if (data.allowances) {
            data.allowances.forEach((item) => {
                const formData = {
                    effective_date: [moment(item.effective_date, 'DD-MM-YYYY').format(), Validators.required],
                    end_date: [item.end_date],
                    currency: [item.currency, item.type !== 'funeral cover' ? Validators.required : ''],
                    amount: [item.amount, item.type !== 'funeral cover' ? Validators.required : ''],
                    type: [item.type]
                };
                this.allowancesInvalid.push(false);
                (this.compensationForm.get('allowances') as FormArray).push(this.fb.group(formData));
            });
        }
        if (data.bursaries) {
            data.bursaries.forEach((item, index: number) => {
                const formData: any = {
                    qualification_name: [item.qualification_name, Validators.required],
                    institution: [item.institution, Validators.required],
                    qualification_length: [item.qualification_length, Validators.required],
                    current_year: [item.current_year, Validators.required],
                    currency: [item.currency, Validators.required],
                    tuition_fee: [item.tuition_fee, Validators.required],
                    additional_allowances: [item.additional_allowances]
                };
                if (item.additional_allowances) {
                    formData.accommodation_allowance = item.accommodation_allowance;
                    formData.book_allowance = item.book_allowance;
                }
                this.bursariesInvalid.push(false);
                (this.compensationForm.get('bursaries') as FormArray).push(this.fb.group(formData));
                this.currentYearOptions[index] = this.qualificationLengthOptions.filter(
                    (q) => q.value <= item.qualification_length
                );
            });
        }
        this.formsCopy.compensation = { ...this.compensationForm.value };
    }

    populateTimeOffFormForm(data) {
        if (data.time_off) {
            data.time_off.forEach((item) => {
                const formData = {
                    amount: [item.amount, Validators.required],
                    type: [item.type, Validators.required]
                };
                this.timeOffInvalid.push(false);
                (this.timeOffForm.get('time_off') as FormArray).push(this.fb.group(formData));
            });
        }
        this.formsCopy.time_off = { ...this.timeOffForm.value };
    }

    populatePersonalForm(data) {
        // console.log(data);
        if (!this.employee.dependents) {
            (this.personalForm.get('dependents') as FormArray).push(
                this.fb.group({
                    full_name: ['', Validators.required],
                    relationship: ['', Validators.required],
                    date_of_birth: ['', Validators.required],
                    gender: ['', Validators.required]
                })
            );
            this.dependentsInvalid.push(true);
        } else {
            this.employee.dependents.forEach((item) => {
                const formData = {
                    full_name: [item.full_name, Validators.required],
                    relationship: [item.relationship, Validators.required],
                    date_of_birth: [moment(item.date_of_birth, 'DD-MM-YYYY').format(), Validators.required],
                    gender: [item.gender || '']
                };
                this.dependentsInvalid.push(false);
                (this.personalForm.get('dependents') as FormArray).push(this.fb.group(formData));
            });
        }

        if (!this.employee.emergency_contacts) {
            (this.personalForm.get('emergency_contacts') as FormArray).push(
                this.fb.group({
                    full_name: ['', Validators.required],
                    relationship: ['', Validators.required],
                    phone: ['', Validators.required],
                    email: [
                        '',
                        [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$')]
                    ]
                })
            );
            this.emergencyContactsInvalid.push(true);
        } else {
            this.employee.emergency_contacts.forEach((item) => {
                const formData = {
                    full_name: [item.full_name, Validators.required],
                    relationship: [item.relationship, Validators.required],
                    phone: [item.phone, Validators.required],
                    email: [
                        item.email,
                        [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$')]
                    ]
                };
                this.emergencyContactsInvalid.push(false);
                (this.personalForm.get('emergency_contacts') as FormArray).push(this.fb.group(formData));
            });
        }
        if (data.date_of_birth) {
            this.personalForm.patchValue({ date_of_birth: moment(data.date_of_birth, 'DD-MM-YYYY').format() });
        }
        // console.log('data', data);
        if (data.same_as_residential) {
            this.personalForm
                .get('postal_address')
                .get('street')
                .disable();
            this.personalForm
                .get('postal_address')
                .get('city')
                .disable();
            this.personalForm
                .get('postal_address')
                .get('state')
                .disable();
            this.personalForm
                .get('postal_address')
                .get('country')
                .disable();
            this.personalForm
                .get('postal_address')
                .get('code')
                .disable();
        }
        this.formsCopy.personal = { ...this.personalForm.value };
    }

    populateJobForm(data) {
        if (data.reporting) {
            data.reporting.forEach((item) => {
                const formData = {
                    id: [item.id],
                    contact: [item.contact, Validators.required],
                    relationship: [item.relationship, Validators.required],
                    first_name: [item.first_name],
                    last_name: [item.last_name],
                    user_color: [item.user_color],
                    email: [item.email]
                };
                this.reportInvalid.push(false);
                (this.jobForm.get('reporting') as FormArray).push(this.fb.group(formData));
            });
        }
        if (this.employee.start_date || data.start_date) {
            this.jobForm.patchValue({
                start_date: moment(this.employee.start_date || data.start_date, 'DD-MM-YYYY').format()
            });
        }
        if (data.probation_start_date) {
            this.jobForm.patchValue({ probation_start_date: moment(data.probation_start_date, 'DD-MM-YYYY').format() });
        }
        if (data.probation_end_date) {
            this.jobForm.patchValue({ probation_end_date: moment(data.probation_end_date, 'DD-MM-YYYY').format() });
        }

        if (data.previous_start_date) {
            this.jobForm.patchValue({ previous_start_date: moment(data.previous_start_date, 'DD-MM-YYYY').format() });
        }
        this.formsCopy.job = { ...this.jobForm.value };
    }

    onReportingSectionRemove(i: number) {
        (this.jobForm.get('reporting') as FormArray).removeAt(i);
        this.reportInvalid.splice(i, 1);
    }

    onDependentSectionRemove(i: number) {
        (this.personalForm.get('dependents') as FormArray).removeAt(i);
        this.dependentsInvalid.splice(i, 1);
    }

    onSalarySectionRemove(i: number) {
        (this.compensationForm.get('salary') as FormArray).removeAt(i);
        this.salaryInvalid.splice(i, 1);
    }

    onBonusSectionRemove(i: number) {
        (this.compensationForm.get('bonuses') as FormArray).removeAt(i);
        this.bonusesInvalid.splice(i, 1);
    }

    onBursarySectionRemove(i: number) {
        (this.compensationForm.get('bursaries') as FormArray).removeAt(i);
        this.bursariesInvalid.splice(i, 1);
    }

    onAllowanceSectionRemove(i: number) {
        (this.compensationForm.get('allowances') as FormArray).removeAt(i);
        this.allowancesInvalid.splice(i, 1);
    }

    onTimeOffSectionRemove(i: number) {
        (this.timeOffForm.get('time_off') as FormArray).removeAt(i);
        this.timeOffInvalid.splice(i, 1);
    }

    onEmergencyContactSectionRemove(i: number) {
        (this.personalForm.get('emergency_contacts') as FormArray).removeAt(i);
        this.emergencyContactsInvalid.splice(i, 1);
    }

    onSectionAdd() {
        if (!this.jobForm.get('reporting').valid) {
            this.jobForm.get('reporting')['controls'].forEach((fg: FormGroup) => {
                if (!fg.valid) {
                    this.formHelper.markFormGroupTouched(fg);
                }
            });
            return;
        }
        (this.jobForm.get('reporting') as FormArray).push(
            this.fb.group({
                contact: ['', Validators.required],
                first_name: [''],
                last_name: [''],
                user_color: [''],
                id: [''],
                email: [''],
                relationship: ['', Validators.required]
            })
        );
        this.reportInvalid.push(true);
    }

    onSectionAddDependent() {
        if (!this.personalForm.get('dependents').valid) {
            this.personalForm.get('dependents')['controls'].forEach((fg: FormGroup) => {
                if (!fg.valid) {
                    this.formHelper.markFormGroupTouched(fg);
                }
            });
            return;
        }
        (this.personalForm.get('dependents') as FormArray).push(
            this.fb.group({
                full_name: ['', Validators.required],
                relationship: ['', Validators.required],
                date_of_birth: ['', Validators.required],
                gender: ['', Validators.required]
            })
        );
        this.dependentsInvalid.push(true);
    }

    onSectionAddEmergencyContact() {
        if (!this.personalForm.get('emergency_contacts').valid) {
            this.personalForm.get('emergency_contacts')['controls'].forEach((fg: FormGroup) => {
                if (!fg.valid) {
                    this.formHelper.markFormGroupTouched(fg);
                }
            });
            return;
        }
        (this.personalForm.get('emergency_contacts') as FormArray).push(
            this.fb.group({
                full_name: ['', Validators.required],
                relationship: ['', Validators.required],
                phone: ['', Validators.required],
                email: [
                    '',
                    [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$')]
                ]
            })
        );
        this.emergencyContactsInvalid.push(true);
    }

    onAddSalarySection() {
        const form = this.compensationForm.get('salary');
        if (!form.valid) {
            form['controls'].forEach((fg: FormGroup) => {
                if (!fg.valid) {
                    this.formHelper.markFormGroupTouched(fg);
                }
            });
            return;
        }
        (form as FormArray).push(
            this.fb.group({
                effective_date: ['', Validators.required],
                end_date: [moment().format('DD-MM-YYYY')],
                currency: ['', Validators.required],
                salary: ['', Validators.required],
                reason: [''],
                variable_pay: [false]
            })
        );
        this.salaryInvalid.push(true);
    }

    onAddBonusSection() {
        const form = this.compensationForm.get('bonuses');
        if (!form.valid) {
            form['controls'].forEach((fg: FormGroup) => {
                if (!fg.valid) {
                    this.formHelper.markFormGroupTouched(fg);
                }
            });
            return;
        }
        (form as FormArray).push(
            this.fb.group({
                effective_date: ['', Validators.required],
                end_date: [moment().format('DD-MM-YYYY')],
                currency: ['', Validators.required],
                amount: ['', Validators.required],
                type: ['']
            })
        );
        this.bonusesInvalid.push(true);
    }

    onAddAllowanceSection() {
        const form = this.compensationForm.get('allowances');
        if (!form.valid) {
            form['controls'].forEach((fg: FormGroup) => {
                if (!fg.valid) {
                    this.formHelper.markFormGroupTouched(fg);
                }
            });
            return;
        }
        (form as FormArray).push(
            this.fb.group({
                effective_date: ['', Validators.required],
                end_date: [moment().format('DD-MM-YYYY')],
                currency: ['', Validators.required],
                amount: ['', Validators.required],
                type: ['']
            })
        );
        this.allowancesInvalid.push(true);
    }

    onAddBursary() {
        const form = this.compensationForm.get('bursaries');
        if (!form.valid) {
            form['controls'].forEach((fg: FormGroup) => {
                if (!fg.valid) {
                    this.formHelper.markFormGroupTouched(fg);
                }
            });
            return;
        }
        (form as FormArray).push(
            this.fb.group({
                qualification_name: ['', Validators.required],
                institution: ['', Validators.required],
                qualification_length: ['', Validators.required],
                current_year: ['', Validators.required],
                currency: ['', Validators.required],
                tuition_fee: ['', Validators.required],
                additional_allowances: [false]
            })
        );
        this.bursariesInvalid.push(true);
    }

    onAddTimeOffSection() {
        const form = this.timeOffForm.get('time_off');
        if (!form.valid) {
            form['controls'].forEach((fg: FormGroup) => {
                if (!fg.valid) {
                    this.formHelper.markFormGroupTouched(fg);
                }
            });
            return;
        }
        (form as FormArray).push(
            this.fb.group({
                amount: ['', Validators.required],
                type: ['', Validators.required]
            })
        );
        this.timeOffInvalid.push(true);
    }

    onChangeSection(section: string) {
        // console.log(this.activeSection, this.employee, this.cv.value);
        if (this.activeSection === 'files') {
            this.uploadQueue = [];
        }
        this.activeSection = section;
        if (section === 'employment') {
            this.cv = this.fb.group({
                languages: this.fb.array([]),
                summary: [this.employee.summary],
                certificates: this.fb.array([]),
                employment_history: this.fb.array([]),
                education: this.fb.array([])
            });
            window.scrollTo(0, 0);
        }
    }

    closeModal() {
        this.close.emit();
    }

    async onSaveAll(e) {
        const form = this.getActiveForm();
        console.log('onSaveAll', this.activeSection, 'Form', form, form.valid);
        if (!form.valid) {
            this.formHelper.markFormGroupTouched(form);
            return;
        }
        this.addToAudit(this.activeSection, form.value);
        this.isFormLoading = true;
        if (this.activeSection === 'job') {
            if (form.value.start_date) {
                form.value.anniversary_date = moment(form.get('start_date').value).format('DD-MM');
                form.value.start_date = moment(form.get('start_date').value).format('DD-MM-YYYY');
            }
            if (form.value.probation_start_date) {
                form.value.probation_start_date = moment(form.get('probation_start_date').value).format('DD-MM-YYYY');
            }
            if (form.value.probation_end_date) {
                form.value.probation_end_date = moment(form.get('probation_end_date').value).format('DD-MM-YYYY');
            }
            if (form.value.previous_start_date) {
                form.value.previous_start_date = moment(form.get('previous_start_date').value).format('DD-MM-YYYY');
            }
            const role = this.employee.role;
            this.employee.start_date = form.value.start_date;
            this.employee.job_location = form.value.job_location;
            this.employee.type = form.value.type;
            this.employee.employee_id = form.value.employee_id;
            this.employee.status = form.value.status;
            this.employee.role = form.value.role;
            if (form.value.job_location) {
                const jobLoc = this.jobLocationOptions.find((l) => l.value === form.value.job_location);
                if (jobLoc && jobLoc.label) {
                    this.employee.job_location_short = jobLoc.label;
                }
            }

            console.log(this.initialRole, form.value.role);

            await this.employeeService.updateEmployee(this.employee.id, {
                start_date: form.value.start_date,
                anniversary_date: form.value.anniversary_date || '',
                job_location: form.value.job_location,
                type: form.value.type,
                job_location_short: this.employee.job_location_short || '',
                employee_id: form.value.employee_id,
                status: form.value.status,
                role: form.value.role,
                ...form.value.personal,
                job_details: {
                    department: form.value.department,
                    designation: form.value.designation,
                    business_unit: form.value.business_unit,
                    level: form.value.level,
                    shift: form.value.shift
                },
                changed: true
            });
            Object.assign(this.employee, form.value.personal);
            const data = { ...form.value };
            delete data.personal;
            delete data.employee_id;
            delete data.status;
            delete data.role;
            await this.employeeService.saveJobDetails(this.employee.id, data);
            this.employeeService
                .updateEmployeeUser(this.employee.email, this.employee)
                .catch((error) => console.error(error));
            this.employee.job_details = form.value;
            console.log(form.value);
            this.employeeService.updateEmployeeObj(this.employee.id, this.employee);
            if (this.employee.role && role !== this.employee.role) {
                console.log(this.employee.role, role);
                this.employeeService.updateUserRole(this.employee.email, this.employee.role).then(() => {
                    console.log('user updated');
                    if (this.initialRole === 'employee' && this.initialRole !== form.value.role) {
                        this.userService.sendInvitationalEmail(this.employee.email).subscribe(() => console.log('-'));
                    }
                });
            }
            if (this.employee.email !== form.value.personal.work_email) {
                console.log(
                    'UPDATE EMP EMAIL',
                    this.employee.email,
                    form.value.personal.work_email,
                    this.employee.email !== form.value.personal.work_email
                );
                const data = {
                    tenantId: this.utilitiesService.getTenant(),
                    email: form.value.personal.work_email
                };
                this.employeeService.updateEmployeeEmail(this.employee.id, data).subscribe(
                    (response) => {
                        console.log(response);
                        this.employee.email = form.value.personal.work_email;
                    },
                    (err) => {
                        console.log('Error');
                        this.toastr.error('Error');
                    }
                );
            }
        } else if (this.activeSection === 'personal') {
            if (
                form.get('residential_address').touched &&
                form.value.residential_address.street &&
                form.value.residential_address.code &&
                form.value.residential_address.street &&
                form.value.residential_address.city &&
                form.value.residential_address.country
            ) {
                let address = `${form.value.residential_address.street}, ${form.value.residential_address.code}, ${form.value.residential_address.street}, ${form.value.residential_address.city}, ${form.value.residential_address.state}, ${form.value.residential_address.country}`;
                const response: any = await this.candidateService
                    .saveCandidateAddress(this.employee.id, { address })
                    .toPromise();
                console.log('response', response);
                if (!response.place) {
                    form.get('residential_address').patchValue({
                        street: null,
                        city: null,
                        state: null,
                        code: null
                    });
                    this.isFormLoading = false;
                    this.toastr.error('Residential Address has error');
                    return;
                } else {
                    const place = response.place;
                    form.get('residential_address').patchValue({
                        street: place.streetAddress,
                        city: place.addressLocality,
                        state: place.addressRegion,
                        country: place.addressCountry,
                        code: place.postalCode
                    });
                    this.employee.address = response.place;
                    this.employee.location = response.formatted_address;
                }
            }
            form.value.dependents.forEach((c, index) => {
                c.date_of_birth = moment(
                    this.personalForm.get('dependents')['controls'][index].get('date_of_birth').value
                ).format('DD-MM-YYYY');
            });
            if (form.value.date_of_birth) {
                form.value.date_of_birth = moment(form.get('date_of_birth').value).format('DD-MM-YYYY');
                form.value.birthday = moment(form.get('date_of_birth').value).format('DD-MM');
            }
            form.value.postal_address = form.getRawValue().postal_address;

            const data = {
                personal: { ...form.value },
                first_name: form.value.first_name,
                last_name: form.value.last_name,
                gender: form.value.gender,
                ethnicity: form.value.ethnicity,
                emergency_contacts: form.value.emergency_contacts,
                dependents: form.value.dependents,
                changed: true
            };
            delete data.personal.first_name;
            delete data.personal.last_name;
            delete data.personal.gender;
            delete data.personal.ethnicity;
            delete data.personal.emergency_contacts;
            delete data.personal.dependents;
            console.log(form.value, this.employee);
            await this.employeeService.updateEmployee(this.employee.id, data);
            this.employee.personal = data.personal;
            this.employee.first_name = data.first_name;
            this.employee.last_name = data.last_name;
            this.employee.gender = data.gender;
            this.employee.ethnicity = data.ethnicity;
        } else if (this.activeSection === 'compensation') {
            form.value.salary.forEach((c, index) => {
                c.effective_date = moment(
                    this.compensationForm.get('salary')['controls'][index].get('effective_date', 'DD-MM-YYYY').value
                ).format('DD-MM-YYYY');
            });
            const cost = form.value.salary[form.value.salary.length - 1].salary;
            const salary_start_date = form.value.salary[form.value.salary.length - 1].effective_date;
            form.value.bonuses.forEach((c, index) => {
                c.effective_date = moment(
                    this.compensationForm.get('bonuses')['controls'][index].get('effective_date', 'DD-MM-YYYY').value
                ).format('DD-MM-YYYY');
            });
            form.value.allowances.forEach((c, index) => {
                c.effective_date = moment(
                    this.compensationForm.get('allowances')['controls'][index].get('effective_date', 'DD-MM-YYYY').value
                ).format('DD-MM-YYYY');
            });
            const data = {
                salary: form.value.salary,
                banking_details: { ...this.employee.banking_details, ...form.value.banking_details },
                cost,
                salary_start_date,
                bonuses: form.value.bonuses || [],
                allowances: form.value.allowances || [],
                bursaries: form.value.bursaries || [],
                changed: true
            };
            await this.employeeService.updateEmployee(this.employee.id, data);
            this.employee.salary = form.value.salary;
            this.employee.banking_details = data.banking_details;
            this.employee.bonuses = form.value.bonuses;
            this.employee.allowances = form.value.allowances;
        } else if (this.activeSection === 'employment') {
            this.contentLoading = true;
            const data = {
                education_history: this.cv.value.education,
                languages: this.cv.value.languages,
                certificates: this.cv.value.certificates || [],
                employment_summary: this.cv.value.summary || '',
                employment_history: this.cv.value.employment_history || [],
                phone: this.employee.phone || '',
                gender: this.employee.gender || null,
                ethnicity: this.employee.ethnicity || null,
                changed: true
            };
            // this.employeeService.saveEmployeeResume(this.employee.id, data).subscribe((response) => {
            //     this.contentLoading = false;
            //     this.employmentSectionUpdated.emit();
            // });
            await this.employeeService.updateEmployee(this.employee.id, data);
            this.contentLoading = false;
        } else if (this.activeSection === 'time_off') {
            const sum = form.value.time_off.reduce((acc, c) => {
                return acc + c.amount;
            }, 0);
            const data = {
                annual_leave: sum,
                time_off: form.value.time_off || [],
                changed: true
            };
            this.employee.annual_leave = sum;
            this.employee.time_off = form.value.time_off || [];
            await this.employeeService.updateEmployee(this.employee.id, data);
        }
        // await this.employeeService.updateEmployeeData(this.employee.id, this.employee);
        await this.candidateService.updateCandidatesDataCollection(this.employee.id).toPromise();
        this.isFormLoading = false;
        // console.log(response);
    }

    addToAudit(section, data) {
        // console.log(section, data, this.employee, this.formsCopy);
        const audit = {
            created_at: Math.floor(Date.now() / 1000),
            user_id: this.user.id,
            type: 'updated',
            section,
            updated: []
        };
        if (section === 'job' || section === 'personal' || section === 'compensation' || section === 'time_off') {
            for (let key in data) {
                const value =
                    this.formsCopy[section] && this.formsCopy[section][key] ? this.formsCopy[section][key] : '';
                const equal = this.utilitiesService.isEqual(value, data[key]);
                if (!equal && (value || data[key]) && audit.updated.indexOf(this.setUpdatedSectionName(key)) === -1) {
                    audit.updated.push(this.setUpdatedSectionName(key));
                }
            }
        }
        if (audit.updated.length > 0) {
            this.employeeService.saveEmployeeAudit(this.employee.id, audit);
        }
        // console.log('audit', audit);
    }

    private getActiveForm(activeSection: string = ''): FormGroup {
        const section = activeSection || this.activeSection;
        let form;
        switch (section) {
            case 'job':
                form = this.jobForm;
                break;
            case 'personal':
                form = this.personalForm;
                break;
            case 'compensation':
                form = this.compensationForm;
                break;
            case 'employment':
                form = this.cv;
                break;
            case 'time_off':
                form = this.timeOffForm;
                break;
        }
        return form;
    }

    onLanguagesAdded(languages) {
        console.log('onlanguagesChange', languages);
        const languageValues = languages.map((lan) => lan.name);
        this.personalForm.get('languages').setValue(languageValues);
        this.personalForm.get('languages').markAsDirty();
    }

    onCheckboxChange(e) {
        if (e.checked) {
            const address = this.personalForm.get('residential_address').value;
            this.personalForm.get('postal_address').patchValue({
                street: address.street,
                city: address.city,
                state: address.state,
                country: address.country,
                code: address.code
            });
            this.personalForm
                .get('postal_address')
                .get('street')
                .disable();
            this.personalForm
                .get('postal_address')
                .get('city')
                .disable();
            this.personalForm
                .get('postal_address')
                .get('state')
                .disable();
            this.personalForm
                .get('postal_address')
                .get('country')
                .disable();
            this.personalForm
                .get('postal_address')
                .get('code')
                .disable();
        } else {
            this.personalForm
                .get('postal_address')
                .get('street')
                .enable();
            this.personalForm
                .get('postal_address')
                .get('city')
                .enable();
            this.personalForm
                .get('postal_address')
                .get('state')
                .enable();
            this.personalForm
                .get('postal_address')
                .get('country')
                .enable();
            this.personalForm
                .get('postal_address')
                .get('code')
                .enable();
        }
    }

    onDropFile(event) {
        console.log('onDropFile');
    }

    sanitizeLink(link) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(link);
    }

    onUploadProfileImage(files) {
        if (files.length === 0) {
            return;
        }
        const mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        this.utilitiesService
            .readAndResizeImage(files[0], 200, 200)
            .then((fileValue: any) => {
                // console.log('req', fileValue);
                fileValue.folder = 'Employees';
                this.workflowService.uploadImage(fileValue).subscribe(
                    (response: any) => {
                        // console.log('response', response);
                        this.contentLoading = false;
                        this.personalForm.get('profile_image').patchValue(response.url);
                        this.image.nativeElement.value = '';
                    },
                    (err) => {
                        console.log(err);
                        this.contentLoading = false;
                    }
                );
            })
            .catch((error) => {
                console.error(error);
                console.error('Error reading uploaded file');
            });
        // reader.onload = (_event) => {
        //     const data = {
        //         folder: 'Employees',
        //         data: reader.result,
        //         name: files[0].name,
        //         size: files[0].size
        //     };
        //     this.contentLoading = true;
        //     console.log(data, files);

        // };
    }

    onAddDocumentBtnClick() {
        this.showUploaderOptions = !this.showUploaderOptions;
    }

    onUploadItemClick(type, category) {
        this.selectedDocumentType = type;
        this.selectedDocumentCategory = category;
        this.showUploaderOptions = false;
    }

    isFileSizeValid(size: number) {
        const maxSize = (this.uploadValidationRules[this.selectedDocumentType] || 5) * 1024 * 1024;
        return size <= maxSize ? true : false;
    }

    private validateFileType(file: File, types: string[]) {
        return types.indexOf(file.type) !== -1;
    }

    onFileUpload(fileEvent: any) {
        const file = fileEvent.target.files[0];
        if (this.isFileSizeValid(file.size)) {
            this.uploadError = null;
            console.log('File size is valid');
        } else {
            this.uploadError = 'File size is too big';
            setTimeout(() => (this.uploadError = null), 10000);
        }
        this.contentLoading = true;
        if (this.validateFileType(file, this.supportedFileTypes)) {
            this.uploadQueue.push({
                file,
                uploadStarted: false,
                uploadFinished: false,
                progress: 0,
                success: false,
                text: file.name,
                type: this.selectedDocumentType,
                category: this.selectedDocumentCategory,
                extension: this.utilitiesService.getExtension(file.name)
            });
            this.processQueue();
        } else {
            this.uploadError = 'Only supported formats are: pdf, doc, docx, rtf, odt';
            this.contentLoading = false;
            setTimeout(() => (this.uploadError = null), 10000);
        }
    }

    processQueue() {
        this.uploadQueue.forEach((item) => {
            if (!item.uploadStarted && !item.uploadFinished) {
                this.uploadFile(item);
                const itemInterval = setInterval(() => {
                    if (item.uploadFinished) {
                        const itemIndex = this.uploadQueue.findIndex((ui) => ui.id === item.id);
                        if (itemIndex !== -1) {
                            this.uploadQueue.splice(itemIndex, 1);
                        }
                        clearInterval(itemInterval);
                    }
                });
            }
        });
    }

    uploadFile(item) {
        this.utilitiesService
            .readFile(item.file)
            .then((fileValue) => {
                item.uploadStarted = true;
                const uploadProgressInterval = setInterval(() => {
                    item.progress = item.progress + 1 < 100 ? item.progress + 1 : item.progress;
                }, 400);
                this.employeeService
                    .addDocumentsToEmployee(this.employee.id, {
                        document: fileValue,
                        documentType: item.type,
                        documentCategory: item.category,
                        name: item.text,
                        status: 'approved'
                    })
                    .subscribe(
                        (response: HttpResponse<any>) => {
                            item.progress = 100;
                            item.uploadFinished = true;
                            item.success = true;
                            item.fadeout = true;
                            clearInterval(uploadProgressInterval);
                            const resp: any = response;
                            this.contentLoading = false;

                            const document = this.employee.documents.find(
                                (d) => d.document_type === resp.employee.document.document_type
                            );
                            if (document) {
                                if (!document.versions) {
                                    document.versions = [];
                                }
                                document.versions.push({
                                    link: document.link,
                                    mime_type: document.mime_type,
                                    original_name: document.original_name,
                                    name: document.name,
                                    uploaded_at: document.uploaded_at
                                });
                                document.link = resp.employee.document.link;
                                document.name = resp.employee.document.name;
                                document.original_name = resp.employee.document.original_name;
                                document.size = resp.employee.document.size;
                                document.uploaded_at = resp.employee.document.uploaded_at;
                            } else {
                                this.employee.documents.push(resp.employee.document);
                            }
                            this.documentUpdated.emit(this.employee.documents);
                            console.log(resp);

                            const audit = {
                                created_at: Math.floor(Date.now() / 1000),
                                user_id: this.user.id,
                                type: 'updated',
                                section: 'document',
                                updated: [this.selectedDocumentCategory]
                            };
                            this.employeeService.saveEmployeeAudit(this.employee.id, audit);
                        },
                        (error) => {
                            console.error(error);
                            item.text =
                                error && error.error && error.error.message ? error.error.error.message : 'Error';
                            item.progress = 100;
                            item.uploadFinished = true;
                            clearInterval(uploadProgressInterval);
                            this.contentLoading = false;
                        }
                    );
            })
            .catch((error) => {
                console.error(error);
                console.error('Error reading uploaded file');
            });
    }

    changeContactForm(event, index) {
        const user = this.users.find((u) => u.id === event.value);
        if (user) {
            this.jobForm.get('reporting')['controls'][index].controls.first_name.patchValue(user.first_name);
            this.jobForm.get('reporting')['controls'][index].controls.last_name.patchValue(user.last_name);
            this.jobForm.get('reporting')['controls'][index].controls.email.patchValue(user.email);
            this.jobForm.get('reporting')['controls'][index].controls.id.patchValue(user.id);
            this.jobForm
                .get('reporting')
                ['controls'][index].controls.user_color.patchValue(user.user_color || '#ffd8be');
        }
    }

    async onDeleteFile(file) {
        this.contentLoading = true;
        await this.employeeService.removeDocument(this.employee.id, file.id);
        const index = this.employee.documents.find((d) => d.id === file.id);
        this.employee.documents.splice(index, 1);
        this.documentUpdated.emit(this.employee.documents);
        // this.findUnapproachableDocument(this.employee.documents);
        this.contentLoading = false;
    }

    findUnapproachableDocument(documents) {
        documents.forEach((doc) => {
            this.uploadedTypesOptions.forEach((o) => {
                const type = o.types.find((t) => t.value === doc.type && t.single);
                if (type) {
                    type.exist = true;
                }
            });
        });
    }

    setUpdatedSectionName(field) {
        if (field === 'reporting') {
            return 'Reporting Information';
        } else if (
            field === 'shift' ||
            field === 'id_card' ||
            field === 'probation_start_date' ||
            field === 'probation_end_date' ||
            field === 'previous_company_name' ||
            field === 'previous_start_date'
        ) {
            return 'Additional Work Information';
        } else if (field === 'personal') {
            return 'General Information';
        } else if (
            field === 'employee_id' ||
            field === 'status' ||
            field === 'start_date' ||
            field === 'type' ||
            field === 'department' ||
            field === 'designation' ||
            field === 'business_unit' ||
            field === 'job_location' ||
            field === 'sub_department' ||
            field === 'primary_team' ||
            field === 'additional_teams' ||
            field === 'level' ||
            field === 'cost_centr' ||
            field === 'role'
        ) {
            return 'Work Information';
        } else if (field === 'residential_address' || field === 'postal_address' || field === 'same_as_residential') {
            return 'Personal Contact Information';
        } else if (field === 'dependents') {
            return 'Dependent Information';
        } else if (field === 'emergency_contacts') {
            return 'Emergency Contact';
        } else if (
            field === 'first_name' ||
            field === 'last_name' ||
            field === 'gender' ||
            field === 'date_of_birth' ||
            field === 'ethnicity' ||
            field === 'marital_status' ||
            field === 'blood_group' ||
            field === 'languages' ||
            field === 'profile_image'
        ) {
            return 'Personal Information';
        } else if (field === 'salary') {
            return 'Salary';
        } else if (field === 'bonuses') {
            return 'Bonus';
        } else if (field === 'allowances') {
            return 'Allowances';
        } else if (field === 'banking_details') {
            return 'Banking Details';
        } else if (field === 'time_off') {
            return 'Time off';
        } else {
            return field;
        }
    }

    onCommissionCheckboxChange(event, i, item) {
        const form = this.compensationForm.get('salary')['controls'][i];
        if (event.checked) {
            form.addControl(
                'commission',
                this.fb.group(
                    {
                        percentage: [''],
                        amount: ['', Validators.required],
                        payout_frequency: ['']
                    },
                    {
                        validator: CompensationValidation.MatchField
                    }
                )
            );
        } else {
            form.removeControl('commission');
        }
    }

    onAdditionalAllowancesCheckboxChange(event, i, item) {
        const form = this.compensationForm.get('bursaries')['controls'][i];
        if (event.checked) {
            form.addControl('accommodation_allowance', new FormControl('', Validators.required));
            form.addControl('book_allowance', new FormControl('', Validators.required));
        } else {
            form.removeControl('accommodation_allowance');
            form.removeControl('book_allowance');
        }
    }

    async checkUserExists() {
        const email = this.jobForm.value.personal.work_email;
        const form = this.jobForm.get('personal').get('work_email');
        // console.log('! checkUserExists', email);
        if (email && email.length) {
            if (this.formHelper.validateEmail(email)) {
                this.contentLoading = true;
                const response = await this.userService.getCandidateExists(email);
                if (response.userExists && email !== this.employee.email) {
                    form.setErrors({ incorrect: true });
                    form.markAsTouched();
                } else {
                    form.setErrors({ incorrect: null });
                    form.updateValueAndValidity();
                }
                this.contentLoading = false;
            }
        }
    }

    onChangeAllowancesTypeOptions(i) {
        const form = this.compensationForm.get('allowances')['controls'][i];
        if (form.value.type === 'funeral cover') {
            form.get('currency').clearValidators();
            form.get('currency').updateValueAndValidity();
            form.get('currency').patchValue(null);
            form.get('amount').clearValidators();
            form.get('amount').updateValueAndValidity();
            form.get('amount').patchValue(null);
        } else {
            form.get('currency').setValidators([Validators.required]);
            form.get('currency').updateValueAndValidity();
            form.get('amount').setValidators([Validators.required]);
            form.get('amount').updateValueAndValidity();
        }
    }

    changeQualificationLength(event: any, index: number) {
        const formGroup = this.compensationForm.get('bursaries')['controls'][index];
        this.currentYearOptions[index] = this.qualificationLengthOptions.filter(
            (q) => q.value <= formGroup.value.qualification_length
        );
        if (formGroup.value.current_year && formGroup.value.qualification_length < formGroup.value.current_year) {
            formGroup.get('current_year').patchValue(null);
        }
    }

    ngOnDestroy(): void {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        if (this.usersSubscription) {
            this.usersSubscription.unsubscribe();
        }
    }
}
