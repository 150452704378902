import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from '@app/core/models';
import { UtilitiesService } from '@app/core/services/utilities.service';
import * as fromStore from '@app/store';
import * as fromSelectors from '@app/store/selectors';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { TenantService } from './tenant.service';

@Injectable({
    providedIn: 'root'
})
export class OffersService {
    apiURL: string = environment.apiUrl;
    private dropdownData$ = new Subject<any>();
    baseURL = '';
    constructor(
        private http: HttpClient,
        private utilities: UtilitiesService,
        private store: Store<fromStore.State>,
        private tenantService: TenantService,
        private db: AngularFirestore
    ) {}

    parseOfferTemplate(formData: object) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/offer-template/parse`, formData);
    }

    getOfferLink(offerFile: string) {
        return this.http.get(`${this.apiURL}/link?url=${offerFile}&collection=Offer_Templates`);
    }

    addOfferTemplate(offerTemplate) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/offers`, offerTemplate);
    }

    getOfferTemplates() {
        return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/offers`);
    }

    createSignature(data) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/offers/get-signature`, data);
    }

    getOfferTemplate(id) {
        return this.http.get(`${this.apiURL}/tenants/${this.utilities.getTenant()}/offers/${id}`);
    }

    getConvertedData(filelink, name, variables) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/offer-template/convert`, {
            data: filelink,
            name,
            variables
        });
    }

    getLocationDetails(location) {
        return this.http.post(`${this.apiURL}/tenants/${this.utilities.getTenant()}/jobs/job-location-details`, {
            location
        });
    }

    convertAutomaticData(jobId, candidateId, data) {
        return this.http.post(
            `${this.apiURL}/tenants/${this.utilities.getTenant()}/${jobId}/${candidateId}/get-info`,
            data
        );
    }

    convertAutomaticDataInside(job, candidate, data) {
        return this.store.pipe(
            select(fromSelectors.getUsersEntities),
            map((users) => {
                try {
                    const finalData = {};
                    if (data.offerCandidateVariables && data.offerCandidateVariables.length) {
                        data.offerCandidateVariables.forEach((variable) => {
                            if (variable.value === 'applicant.name') {
                                finalData['applicant.name'] = candidate.first_name + ' ' + candidate.last_name;
                            }

                            if (variable.value === 'applicant.first_name') {
                                finalData['applicant.first_name'] = candidate.first_name;
                            }

                            if (variable.value === 'applicant.last_name') {
                                finalData['applicant.last_name'] = candidate.last_name;
                            }

                            if (variable.value === 'applicant.address') {
                                finalData['applicant.address'] = candidate.location;
                            }

                            if (variable.value === 'applicant.email') {
                                finalData['applicant.email'] = candidate.email;
                            }
                        });
                    }

                    if (data.offerCompanyVariables && data.offerCompanyVariables.length) {
                        data.offerCompanyVariables.forEach((variable) => {
                            if (variable.value === 'tenant.company_name') {
                                finalData['tenant.company_name'] = job.company;
                            }

                            if (variable.value === 'tenant.company_location') {
                                finalData['tenant.company_location'] = job.location ? job.location : '';
                            }

                            if (variable.value === 'tenant.company_city') {
                                finalData['tenant.company_city'] = '';
                            }

                            if (variable.value === 'tenant.company_country') {
                                finalData['tenant.company_country'] = '';
                            }

                            if (variable.value === 'tenant.name') {
                                finalData['tenant.name'] = job.company;
                            }
                        });
                    }

                    if (data.offerJobVariables && data.offerJobVariables.length) {
                        data.offerJobVariables.forEach((variable) => {
                            if (variable.value === 'job.title') {
                                finalData['job.title'] = job.title;
                            }

                            if (variable.value === 'job.type') {
                                finalData['job.type'] = job.type ? job.type : '';
                            }

                            if (variable.value === 'doer.name') {
                                const user = users.find((user) => user.id === job.owner);
                                if (user) {
                                    finalData['doer.name'] = user.first_name + ' ' + user.last_name;
                                }
                            }

                            if (variable.value === 'doer.email') {
                                const user = users.find((user) => user.id === job.owner);
                                if (user) {
                                    finalData['doer.email'] = user.email;
                                }
                            }

                            if (variable.value === 'hiring_manager.name') {
                                if (!job.hiring_managers[0]) {
                                    finalData['hiring_manager.name'] = 'not-found';
                                } else {
                                    const user = users.find((user) => user.id === job.hiring_managers[0]);
                                    if (user) {
                                        finalData['hiring_manager.name'] =
                                            user.first_name && user.last_name
                                                ? user.first_name + ' ' + user.last_name
                                                : '';
                                    }
                                }
                            }

                            if (variable.value === 'hiring_manager.email' && job.hiring_managers[0]) {
                                if (!job.hiring_managers[0]) {
                                    finalData['hiring_manager.email'] = 'not-found';
                                } else {
                                    const user = users.find((user) => user.id === job.hiring_managers[0]);
                                    finalData['hiring_manager.email'] = user.email ? user.email : '';
                                }
                            }
                        });
                    }

                    //    General
                    finalData['applicant.name'] = candidate.first_name + ' ' + candidate.last_name;
                    const user = users.find((user) => user.id === job.owner);
                    finalData['doer.name'] = user.first_name + ' ' + user.last_name;
                    finalData['date.today'] = this.getTodayDate();
                    return finalData;
                } catch (e) {
                    console.log(e);
                    throw new Error('Something went wrong');
                }
            })
        );
    }

    getTodayDate() {
        let date = new Date();
        let monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];

        let day = date.getDate();

        let monthIndex = date.getMonth();
        let monthName = monthNames[monthIndex];

        let year = date.getFullYear();

        return `${day} ${monthName} ${year}`;
    }

    updateOfferTemplate(id, offerTemplate) {
        return this.http.put(`${this.apiURL}/tenants/${this.utilities.getTenant()}/offers/${id}`, offerTemplate);
    }

    deleteOfferTemplate(id) {
        return this.http.delete(`${this.apiURL}/tenants/${this.utilities.getTenant()}/offers/${id}`);
    }

    getHiringTeam() {
        this.store.pipe(select(fromSelectors.getUsersEntities)).subscribe((users: User[]) => {
            const selectedItems = [];
            users.forEach((user) => {
                const u = { ...user };
                if (u.role === 'recruiter') {
                    selectedItems.push({
                        value: `${u.first_name} ${u.last_name}`,
                        label: `${u.first_name} ${u.last_name}`
                    });
                }
            });

            this.dropdownData$.next({ data: selectedItems, type: 'hiring_team' });
        });
    }

    getLocations() {
        this.tenantService.init();
        this.tenantService.locations().subscribe((locations: any[]) => {
            const selectedItems = [];
            if (locations) {
                locations.forEach((l) => {
                    if (l.location === 'any') {
                        selectedItems.push({ value: `${l.name}`, label: l.location });
                    } else {
                        selectedItems.push({
                            value: `${l.name}: ${l.location}`,
                            label: `${l.name}: ${l.location}`
                        });
                    }
                });
                this.dropdownData$.next({ data: selectedItems, type: 'locations' });
            }
        });
    }

    getDropdownData() {
        return this.dropdownData$.asObservable();
    }

    declineOffer(candidateId, offerId) {
        return new Promise(async (resolve, reject) => {
            try {
                await this.db
                    .collection(`tenants/${this.utilities.getTenant()}/candidates/${candidateId}/assignments`)
                    .doc(offerId)
                    .update({ completed: true, offer_accepted: false, updated_at: Math.floor(Date.now() / 1000) });
                resolve(null);
            } catch (error) {
                return reject(error);
            }
        });
    }

    retractOffer(jobId, candidateId, offer): any {
        return new Promise(async (resolve, reject) => {
            try {
                const job: any = await this.db
                    .collection(`tenants/${this.utilities.getTenant()}/jobs-items`)
                    .doc(jobId)
                    .valueChanges()
                    .pipe(take(1))
                    .toPromise();
                const offer_signs = job.offer_signs;
                await this.db
                    .collection(`tenants/${this.utilities.getTenant()}/candidates/${candidateId}/assignments`)
                    .doc(offer.id)
                    .update({ retracted: true, retracted_at: Math.floor(Date.now() / 1000) });
                for (let item of job.offer_signs[candidateId]) {
                    if (item.label !== 'Candidate') {
                        const user: any = await this.db
                            .collection(`users`)
                            .doc(item.id)
                            .valueChanges()
                            .pipe(take(1))
                            .toPromise();
                        const candidate: any = await this.db
                            .collection(`tenants/${this.utilities.getTenant()}/candidates`, (ref) =>
                                ref.where('email', '==', user.email).limit(1)
                            )
                            .valueChanges({ idField: 'id' })
                            .pipe(take(1))
                            .toPromise();
                        const record: any = await this.db
                            .collection(
                                `tenants/${this.utilities.getTenant()}/candidates/${candidate[0].id}/tasks`,
                                (ref) => ref.where('signature_id', '==', item.signature_id).limit(1)
                            )
                            .valueChanges({ idField: 'id' })
                            .pipe(take(1))
                            .toPromise();
                        if (record && record[0]) {
                            await this.db
                                .collection(`tenants/${this.utilities.getTenant()}/candidates/${candidate[0].id}/tasks`)
                                .doc(record[0].id)
                                .update({ retracted: true });
                        }
                    }
                    item.signed = false;
                    // item.retract = true;
                }
                await this.db
                    .collection(`tenants/${this.utilities.getTenant()}/jobs-items`)
                    .doc(jobId)
                    .update({ offer_signs });
                await this.db
                    .collection(`tenants/${this.utilities.getTenant()}/candidates/${candidateId}/assignments`)
                    .doc(offer.option)
                    .delete();
                const candidate: any = await this.db
                    .collection(`tenants/${this.utilities.getTenant()}/candidates`)
                    .doc(candidateId)
                    .valueChanges()
                    .pipe(take(1));
                const workflows =
                    candidate.workflows && candidate.workflows.length
                        ? candidate.workflows.filter((id) => id !== offer.option)
                        : [];
                await this.db
                    .collection(`tenants/${this.utilities.getTenant()}/candidates`)
                    .doc(candidateId)
                    .update({ workflows });
                resolve(null);
            } catch (error) {
                return reject(error);
            }
        });
    }

    getOfferAssessment(candidateId, offerId): any {
        return new Promise(async (resolve, reject) => {
            try {
                const ass = await this.db
                    .collection(`tenants/${this.utilities.getTenant()}/candidates/${candidateId}/assignments`)
                    .doc(offerId)
                    .valueChanges()
                    .pipe(take(1))
                    .toPromise();
                resolve(ass);
            } catch (error) {
                return reject(error);
            }
        });
    }

    getOfferApprovalDetails(candidateId, jobId) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/candidates-offer-approval/${candidateId}/data`)
            .doc(jobId)
            .valueChanges()
            .pipe(take(1));
    }

    getOfferApprovalDetails2(candidateId, jobId) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/candidates-offer-approval/${candidateId}/data`)
            .doc(jobId)
            .valueChanges();
    }

    getOfferTracking(candidateId, jobId) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/candidates-offer-approval/${candidateId}/tracking`)
            .doc(jobId)
            .valueChanges();
    }

    getJobOfferSigns(candidateId, jobId) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/jobs-items`)
            .doc(jobId)
            .valueChanges()
            .pipe(
                map((job: any) => {
                    if (job) {
                        job.id = jobId;
                        return job.offer_signs[candidateId] || [];
                    } else {
                        return null;
                    }
                })
            );
    }

    getEmployeeOfferTrackings(candidateId) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/employees-offer-approval/${candidateId}/tracking`)
            .valueChanges({ idField: 'id' });
    }

    getAllEmployeeOfferApprovalDetails(candidateId) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/employees-offer-approval/${candidateId}/data`, (ref) =>
                ref.orderBy('created_at', 'desc')
            )
            .valueChanges({ idField: 'id' });
    }

    getEmployeeOfferApprovalDetails(candidateId, recordId) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/employees-offer-approval/${candidateId}/data`)
            .doc(recordId)
            .valueChanges();
    }

    updateOfferApprovalDetails(candidateId, jobId, data) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/candidates-offer-approval/${candidateId}/data`)
            .doc(jobId)
            .update(data);
    }

    getTerminationApprovalDetails(candidateId) {
        return this.db
            .collection(`tenants/${this.utilities.getTenant()}/termination-letter-approval`)
            .doc(candidateId)
            .valueChanges();
    }
}
