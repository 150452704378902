import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { TenantService } from '@app/core/services/tenant.service';
import { currencyOptions, fts_reasons, fts_reasons_extra, salaryOptions } from '@app/core/constants/options.constants';
import { SelectItem } from 'primeng';
import { FormHelperService } from '@app/core/services/form-helper.service';
import * as moment from 'moment';
import { EmployeesService } from '@app/core/services/employees.service';
import { CandidateService } from '@app/core/services';
import { Candidate, Job, User } from '@app/core/models';
import { select, Store } from '@ngrx/store';
import * as fromStore from '@app/store';
import * as fromSelectors from './../../../../../../store/selectors';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'app-employee-offer-letter',
    templateUrl: './employee-offer-letter.component.html',
    styleUrls: ['./employee-offer-letter.component.scss']
})
export class EmployeeOfferLetterComponent implements OnInit, OnDestroy {
    @Input() candidate: Candidate;
    @Input() workflow: any[];
    @Input() workflows: any[];
    @Input() sendContract: boolean = false;
    @Output() close = new EventEmitter<boolean>();
    @Output() approval = new EventEmitter();
    contentLoading = false;
    form: FormGroup;
    jobLocationOptions: any[] = [];
    contactsOptions: SelectItem[] = [];
    usersOptions: SelectItem[] = [];
    timeOffOptions: any[] = [
        { value: 17, label: '17' },
        { value: 18, label: '18' },
        { value: 19, label: '19' },
        { value: 20, label: '20' },
        { value: 21, label: '21' },
        { value: 22, label: '22' },
        { value: 23, label: '23' },
        { value: 24, label: '24' },
        { value: 25, label: '25' },
        { value: 26, label: '26' },
        { value: 27, label: '27' },
        { value: 28, label: '28' },
        { value: 29, label: '29' },
        { value: 30, label: '30' }
    ];
    payScheduleOptions = [
        { value: 'hourly', label: 'per hour' },
        { value: 'daily', label: 'per day' },
        { value: 'weekly', label: 'per week' },
        { value: 'monthly', label: 'per month' },
        { value: 'yearly', label: 'per year' }
    ];
    comissionTypeOptions = [
        { value: 'fixed', label: 'Value' },
        { value: 'percentage', label: 'Percentage' }
    ];
    bonusTypeOptions = [
        { label: '13th Cheque', value: '13th cheque', selected: false },
        { label: 'Performance Bonus', value: 'performance bonus', selected: false }
    ];
    allowancesTypeOptions = [
        { label: 'Mobile Phone', value: 'mobile phone', selected: false },
        { label: 'Petrol Card', value: 'petrol', selected: false },
        { label: 'Petrol Allowance', value: 'petrol allowance', selected: false },
        { label: 'Motor Vehicle Allowance', value: 'motor vehicle allowance', selected: false },
        { label: 'Funeral Cover', value: 'funeral cover', selected: false }
    ];
    currencyOptions: SelectItem[] = currencyOptions;
    businessUnitOptions: SelectItem[] = [];
    salaryOptions: SelectItem[] = salaryOptions;
    companyTypeOptions: any[] = [
        { value: 'dimensiondata', label: 'Dimension Data' },
        { value: 'britehouse', label: 'Britehouse' }
    ];
    view = 'details';
    usersSubscription: Subscription;
    userSubscription: Subscription;
    users: User[];
    dropdownValueUpdated = false;
    previewData = {
        start_date: '',
        end_date: '',
        location_short: '',
        original_start_date: ''
    };
    ftsReasonsArray: FormArray = this.fb.array([]);
    minEndDate = moment().toDate();
    minOriginalDate = moment().toDate();
    previewContractLink: string = '';
    user: User;
    @Input() internalDomains: string[] = [];
    constructor(
        private employeeService: EmployeesService,
        private candidateService: CandidateService,
        private formHelper: FormHelperService,
        private fb: FormBuilder,
        private tenantService: TenantService,
        private cdr: ChangeDetectorRef,
        private store: Store<fromStore.State>,
        private toaster: ToastrService
    ) {
        fts_reasons.forEach((r: string) => {
            let disabled = false;
            this.ftsReasonsArray.push(
                this.fb.group({
                    title: r,
                    checked: true,
                    disabled
                })
            );
        });
        this.ftsReasonsArray.push(
            this.fb.group({
                title: '',
                checked: false
            })
        );
    }

    async ngOnInit() {
        let offer = this.candidate['offer-approval'] || {};
        this.tenantService.locations().subscribe((response) => {
            if (response) {
                response = response.filter((l) => l.name !== 'Any of the Dimension Data Offices');
                response.forEach((l) => {
                    this.jobLocationOptions.push({
                        label: `${l.name} - ${l.location_short}`,
                        value: l.location,
                        short_label: l.location_short,
                        currency: l.currency
                    });
                });
                this.dropdownValueUpdated = true;
                setTimeout(() => {
                    this.dropdownValueUpdated = false;
                }, 100);
                this.cdr.markForCheck();
            }
        });

        this.usersSubscription = this.store.pipe(select(fromSelectors.getUsersEntities)).subscribe((users: User[]) => {
            this.users = [...users];
            this.users.sort((a: any, b: any) => {
                if (a.first_name && b.first_name) {
                    const labelA = a.first_name.toUpperCase();
                    const labelB = b.first_name.toUpperCase();
                    return labelA.localeCompare(labelB);
                }
            });
            this.contactsOptions = this.users
                .filter(
                    (u) => this.internalDomains.length === 0 || this.internalDomains.some((d) => u.email.includes(d))
                )
                .filter((u) => u.email !== this.candidate.email)
                .map((u) => {
                    return {
                        label: `${u.first_name} ${u.last_name}`,
                        value: u.id
                    };
                });
            this.usersOptions = this.users
                .filter(
                    (u) => this.internalDomains.length === 0 || this.internalDomains.some((d) => u.email.includes(d))
                )
                .filter((u) => u.email !== this.candidate.email)
                .map((u) => {
                    return {
                        label: `${u.first_name} ${u.last_name}`,
                        value: u.id
                    };
                });
        });
        this.userSubscription = this.store.pipe(select(fromSelectors.getUserEntity)).subscribe((user: User) => {
            this.user = user;
        });
        this.form = this.fb.group({
            full_name: [
                `${this.candidate.first_name} ${this.candidate.last_name}`,
                [Validators.required, Validators.pattern('\\b\\w+\\b(?:.*?\\b\\w+\\b){1}')]
            ],
            identity_number: ['', Validators.required],
            designation: ['', Validators.required],
            start_date: ['', Validators.required],
            end_date: [''],
            original_start_date: [''],
            business_unit: ['', Validators.required],
            location: ['', Validators.required],
            time_off: ['', Validators.required],
            manager: ['', Validators.required],
            hiring_executive: ['', Validators.required],
            hr_business_partner: ['', Validators.required],
            payout_frequency: [],
            bonuses: this.fb.array([]),
            allowances: this.fb.array([]),
            salary: this.fb.group({
                end_date: [moment().format('DD-MM-YYYY')],
                currency: ['ZAR', Validators.required],
                period: [''],
                salary: [null, Validators.required],
                variable_pay: [false],
                commission_amount: [null],
                on_target_earnings: [null],
                shift_allowance: [null]
            }),
            workflow_id: [''],
            ftc_reasons: this.ftsReasonsArray,
            details: this.fb.group({
                send_offer_letter: [],
                offer_type: [],
                original_appointment: [],
                is_foreign_national: [],
                work_schedule: [null, Validators.required],
                send_onboarding_workflow: [true],
                company: ['dimensiondata']
            })
        });
        this.form.get('details.offer_type').valueChanges.subscribe((value) => {
            if (value === 'permanent') {
                this.form.get('salary.period').clearValidators();
                this.form.get('salary.period').updateValueAndValidity();
            } else if (value === 'fixed') {
                this.form.get('salary.period').setValidators([Validators.required]);
                this.form.get('salary.period').updateValueAndValidity();
            }
        });
        this.form.get('location').valueChanges.subscribe((value) => {
            if (value) {
                const loc = this.jobLocationOptions.find((j) => j.value === value);
                this.form.get('salary.currency').patchValue(loc ? loc.currency : 'ZAR');
            }
        });
        if (this.candidate.job_details) {
            this.form.get('business_unit').patchValue(this.candidate.job_details.business_unit);
        }
        this.form.get('start_date').valueChanges.subscribe((value) => {
            if (value) {
                this.minEndDate = value;
                if (
                    this.form.get('end_date').value &&
                    moment(value).unix() > moment(this.form.get('end_date').value).unix()
                ) {
                    this.form.get('end_date').patchValue(null);
                }
            }
        });
        this.form.get('bonuses').valueChanges.subscribe((changes) => {
            this.bonusTypeOptions.forEach((o) => (o.selected = false));
            changes.forEach((c) => {
                const option = this.bonusTypeOptions.find((a) => a.value === c.type);
                if (option) {
                    option.selected = true;
                }
            });
        });
        this.form.get('allowances').valueChanges.subscribe((changes) => {
            this.allowancesTypeOptions.forEach((o) => (o.selected = false));
            changes.forEach((c) => {
                const option = this.allowancesTypeOptions.find((a) => a.value === c.type);
                if (option) {
                    option.selected = true;
                }
            });
        });
        if (this.candidate.personal && this.candidate.personal.identity_number) {
            this.form.get('identity_number').patchValue(this.candidate.personal.identity_number);
        } else {
            const criminalAss: any = await this.candidateService.getCriminalCheckAss(this.candidate.id);
            if (criminalAss && criminalAss.data && criminalAss.data.id) {
                this.form.get('identity_number').patchValue(criminalAss.data.id);
            }
        }
        if (this.candidate?.time_off?.find((t) => t.type === 'earned')) {
            this.form.get('time_off').patchValue(this.candidate?.time_off.find((t) => t.type === 'earned').amount);
        }
        if (this.candidate.start_date) {
            this.form.get('start_date').patchValue(moment(this.candidate.start_date, 'DD-MM-YYYY').format());
        }
        if (this.candidate.job_location) {
            this.form.get('location').patchValue(this.candidate.job_location);
            const loc = this.jobLocationOptions.find((j) => j.value === this.candidate.job_location);
            this.form.get('salary.currency').patchValue(loc ? loc.currency : 'ZAR');
        }
        if (this.candidate.job_details?.designation) {
            this.form.get('designation').patchValue(this.candidate.job_details?.designation);
        }
        if (this.candidate?.salary?.length) {
            const salary = this.candidate.salary[0];
            const form = this.form.get('salary') as FormGroup;
            form.patchValue({
                end_date: salary.end_date,
                currency: salary.currency,
                salary: salary.salary,
                variable_pay: salary.variable_pay || false
            });

            if (salary.variable_pay && salary.commission) {
                const commission = salary.commission;
                let type;
                if (commission.amount) {
                    type = 'fixed';
                } else if (commission.payout_frequency) {
                    type = 'percentage';
                }
                // form.addControl(
                //     'commission',
                //     this.fb.group({
                //         type: [type, Validators.required],
                //         amount: [commission.amount || null, Validators.required],
                //         payout_frequency: [commission.payout_frequency || '']
                //     })
                // );
            }
        }
        if (this.candidate?.job_details?.reporting?.length) {
            const reporting = this.candidate.job_details.reporting;
            const manager = reporting.find((r) => r.relationship === 'manager');
            const hr_business_partner = reporting.find((r) => r.relationship === 'hr_business_partner');
            if (manager) {
                this.form.get('manager').patchValue(manager.id);
            }
            if (hr_business_partner) {
                this.form.get('hr_business_partner').patchValue(hr_business_partner.id);
            }
        }
        this.store
            .pipe(
                select(fromSelectors.getBusinessUnits),
                filter((res) => !!res)
            )
            .subscribe(
                (businessUnits: any) => {
                    this.businessUnitOptions = businessUnits.sort((a, b) => a.value.localeCompare(b.value));
                },
                (errorResponse) => {
                    console.error(errorResponse);
                }
            );
        if (this.sendContract) {
            this.form
                .get('details')
                .get('send_offer_letter')
                .patchValue(true);
        }
    }

    onBack() {
        console.log('back', this.view);
        if (this.view === 'compensation') {
            this.view = 'default';
        } else if (this.view === 'preview') {
            this.view = 'compensation';
        } else if (this.view === 'default') {
            this.view = 'details';
        } else if (this.view === 'options') {
            this.view = 'default';
        }
    }

    onPreview() {
        console.log('onPreview', this.form.value);
        if (!this.form.valid) {
            this.formHelper.markFormGroupTouched(this.form);
            this.cdr.detectChanges();
            return;
        }
        this.previewData.start_date = this.form.value.start_date;
        this.previewData.end_date = this.form.value.end_date;
        this.previewData.original_start_date = this.form.value.original_start_date;
        const location = this.jobLocationOptions.find((l) => l.value === this.form.value.location);
        if (location) {
            this.previewData.location_short = location.short_label;
        }
        this.view = 'preview';
        this.previewContractLink = '';
    }

    onNext() {
        console.log('onNext', this.form.value);
        const fields = [
            'full_name',
            'identity_number',
            'designation',
            'business_unit',
            'start_date',
            'end_date',
            'location',
            'time_off',
            'original_start_date',
            'manager',
            'hiring_executive',
            'hr_business_partner'
        ];

        for (let field of fields) {
            if (!this.form.get(field).valid) {
                const group = this.form.get(field) as FormGroup;
                group.markAsTouched();
                return;
            }
        }
        if (
            this.form.value.manager === this.form.value.hiring_executive ||
            this.form.value.manager === this.form.value.hr_business_partner ||
            this.form.value.hiring_executive === this.form.value.hr_business_partner
        ) {
            this.toaster.error('Signers with the same email address are not allowed');
            return;
        }
        this.view = this.form.value.details.offer_type === 'fixed' ? 'options' : 'compensation';
        if (
            this.form.value.details.offer_type === 'fixed' &&
            this.form.value.details.is_foreign_national &&
            !this.form.get('ftc_reasons').value.find((f) => f.title === fts_reasons_extra)
        ) {
            const length = this.form.get('ftc_reasons').value.length;
            (this.form.get('ftc_reasons') as FormArray).insert(
                length - 1,
                this.fb.group({
                    title: fts_reasons_extra,
                    checked: true,
                    disabled: true
                })
            );
        } else if (this.form.value.details.offer_type === 'fixed') {
            const index = this.form.get('ftc_reasons').value.findIndex((f) => f.title === fts_reasons_extra);
            if (index > -1) {
                (this.form.get('ftc_reasons') as FormArray).removeAt(index);
            }
        }
        if (this.form.value.details.offer_type === 'fixed') {
            (this.form.get('allowances') as FormArray).clear();
            (this.form.get('bonuses') as FormArray).clear();
        } else {
            if (this.candidate.allowances && this.candidate.allowances.length) {
                this.candidate.allowances.forEach((item) => {
                    (this.form.get('allowances') as FormArray).push(
                        this.fb.group({
                            amount: [item.amount, item.type !== 'funeral cover' ? Validators.required : ''],
                            type: [item.type, Validators.required]
                        })
                    );
                });
            }
            if (this.candidate.bonuses && this.candidate.bonuses.length) {
                this.candidate.bonuses.forEach((item) => {
                    (this.form.get('bonuses') as FormArray).push(
                        this.fb.group({
                            amount: [item.amount, Validators.required],
                            type: [item.type, Validators.required]
                        })
                    );
                });
            }
        }
    }

    onCompensation() {
        console.log('compensation', this.view);
        this.view = 'compensation';
    }

    onSendOffer() {
        console.log('onSendOffer', this.form.valid, this.form, this.form.value);
        if (!this.form.valid) {
            this.formHelper.markFormGroupTouched(this.form);
            return;
        }
        this.contentLoading = true;
        // this.form.value.start_date = moment(this.form.value.start_date).format('DD-MM-YYYY');
        // this.form.value.status = 'pending';
        // this.form.value.ftc_reasons = this.form.value.ftc_reasons
        //     .filter((f) => f.checked && f.title)
        //     .map((f, index) => {
        //         return { content: f.title, index: index + 1 };
        //     });
        // if (this.form.value.details && this.form.value.details.days_of_work) {
        //     this.form.value.details.days_of_work = this.form.value.details.days_of_work
        //         .filter((f) => f.checked)
        //         .map((f) => {
        //             return { content: f.value };
        //         });
        // }
        // if (this.form.value.end_date) {
        //     this.form.value.end_date = moment(this.form.value.end_date).format('DD-MM-YYYY');
        // }
        // if (this.form.value.original_start_date) {
        //     this.form.value.original_start_date = moment(this.form.value.original_start_date).format('DD-MM-YYYY');
        // }
        const data: any = this.prepareFormBeforeSend();
        if (this.form.value.details && this.form.value.details.send_offer_letter) {
            this.employeeService.employeeOfferApproval(this.candidate.id, data).subscribe(
                (response: any) => {
                    console.log('employeeOfferApproval response', response);
                    this.approval.emit({ candidateId: this.candidate.id });
                    this.contentLoading = false;
                    this.cdr.detectChanges();
                },
                (err) => {
                    console.log(err);
                    this.toaster.error(err.error.error);
                    this.contentLoading = false;
                    this.cdr.detectChanges();
                }
            );
        } else {
            data.record_id = this.workflow;
            this.employeeService.employeeOfferAccepted(this.candidate.id, data).subscribe(
                () => {
                    this.approval.emit({ candidateId: this.candidate.id });
                    this.contentLoading = false;
                    this.cdr.detectChanges();
                },
                (err) => {
                    console.log(err);
                    this.toaster.error(err.error.error);
                    this.contentLoading = false;
                    this.cdr.detectChanges();
                }
            );
        }
    }

    prepareFormBeforeSend() {
        const data = { ...this.form.value };
        data.start_date = moment(this.form.value.start_date).format('DD-MM-YYYY');
        data.status = 'pending';
        data.ftc_reasons = this.form.value.ftc_reasons
            .filter((f) => f.checked && f.title)
            .map((f, index) => {
                return { content: f.title, index: index + 1 };
            });
        if (this.form.value.details && this.form.value.details.days_of_work) {
            data.details.days_of_work = this.form.value.details.days_of_work
                .filter((f) => f.checked)
                .map((f) => {
                    return { content: f.value };
                });
        }
        if (this.form.value.end_date) {
            data.end_date = moment(this.form.value.end_date).format('DD-MM-YYYY');
        }
        if (this.form.value.original_start_date) {
            data.original_start_date = moment(this.form.value.original_start_date).format('DD-MM-YYYY');
        }
        return data;
    }

    onPreviewContract() {
        const data = this.prepareFormBeforeSend();
        this.contentLoading = true;
        this.employeeService.employeeOfferAccepted(this.candidate.id, data, 'preview').subscribe(
            (res: any) => {
                console.log('employeeOfferAccepted', res);
                this.previewContractLink = res.previewContractLink;
                this.contentLoading = false;
                this.cdr.detectChanges();
            },
            (err) => {
                console.log(err);
                this.toaster.error('Preview Contract Error');
                this.contentLoading = false;
            }
        );
    }

    // onCommissionCheckboxChange(event) {
    //     const form = this.form.get('salary') as FormGroup;
    //     if (event.checked) {
    //         form.addControl(
    //             'commission',
    //             this.fb.group({
    //                 type: ['', Validators.required],
    //                 amount: [null, Validators.required],
    //                 payout_frequency: ['']
    //             })
    //         );
    //     } else {
    //         form.removeControl('commission');
    //     }
    // }

    onAddBonusSection() {
        const form = this.form.get('bonuses');
        if (!form.valid) {
            form['controls'].forEach((fg: FormGroup) => {
                if (!fg.valid) {
                    this.formHelper.markFormGroupTouched(fg);
                }
            });
            return;
        }
        (form as FormArray).push(
            this.fb.group({
                amount: [null, Validators.required],
                type: ['', Validators.required]
            })
        );
    }

    onDeleteBonus(i) {
        const form = this.form.get('bonuses');
        (form as FormArray).removeAt(i);
    }

    onAddAllowanceSection() {
        const form = this.form.get('allowances');
        if (!form.valid) {
            form['controls'].forEach((fg: FormGroup) => {
                if (!fg.valid) {
                    this.formHelper.markFormGroupTouched(fg);
                }
            });
            return;
        }
        (form as FormArray).push(
            this.fb.group({
                amount: [null, Validators.required],
                type: ['', Validators.required]
            })
        );
    }

    onDeleteAllowance(i) {
        const form = this.form.get('allowances');
        (form as FormArray).removeAt(i);
    }

    onHideOfferModal() {
        this.close.emit();
    }

    onChangeAllowancesTypeOptions(i) {
        const form = this.form.get('allowances')['controls'][i];
        if (form.value.type === 'funeral cover') {
            form.get('amount').clearValidators();
            form.get('amount').updateValueAndValidity();
            form.get('amount').patchValue(null);
        } else {
            form.get('amount').setValidators([Validators.required]);
            form.get('amount').updateValueAndValidity();
        }
    }

    getUserName(id) {
        const user = this.users.find((u) => u.id === id);
        if (user) {
            return user.full_name;
        } else {
            return id;
        }
    }

    onHideContent(event) {
        (event.target as HTMLInputElement).closest('.preview-block').children[1].classList.toggle('hide');
    }

    onChangeCheckboxOptions(event: MatCheckboxChange, type: string = null) {
        if (event.checked && type === 'manually') {
            this.form.get('ftc_reasons')['controls'].forEach((c: any, i: number) => {
                if (this.form.get('ftc_reasons')['controls'].length - 1 !== i && !c.value.disabled) {
                    c.get('checked').patchValue(false);
                }
            });
        } else if (event.checked) {
            const length = this.form.get('ftc_reasons')['controls'].length;
            this.form
                .get('ftc_reasons')
                ['controls'][length - 1].get('checked')
                .patchValue(false);
        }
    }

    onAddDetails() {
        console.log(this.form.value, this.form.valid, this.form);
        if (!this.form.get('details').valid) {
            return;
        }
        if (this.form.value.details.start_time && this.form.value.details.end_time) {
            const start = moment(this.form.value.details.start_time, 'HH:mm').unix();
            const end = moment(this.form.value.details.end_time, 'HH:mm').unix();
            if (start > end) {
                this.form
                    .get('details')
                    .get('end_time')
                    .patchValue(null);
                this.form
                    .get('details')
                    .get('end_time')
                    .markAsTouched();
                return;
            }
        }
        const offer_type = this.form.value.details.offer_type;
        if (offer_type === 'fixed') {
            if (this.user.tenant_id === 'D9BO1JR3D' || this.user.tenant_id === 'dimensiondata') {
                const workflow = this.workflows.find(
                    (w) => w.offer_type === 'fixed' && this.form.value.details.company === w.company
                );
                if (workflow) {
                    this.form.get('workflow_id').patchValue(workflow.id);
                }
            } else {
                const workflow = this.workflows.find((w) => w.offer_type === 'fixed');
                if (workflow) {
                    this.form.get('workflow_id').patchValue(workflow.id);
                }
            }
        } else if (offer_type === 'permanent') {
            if (this.user.tenant_id === 'D9BO1JR3D' || this.user.tenant_id === 'dimensiondata') {
                const workflow = this.workflows.find(
                    (w) => w.offer_type === 'perm' && this.form.value.details.company === w.company
                );
                if (workflow) {
                    this.form.get('workflow_id').patchValue(workflow.id);
                }
            } else {
                const workflow = this.workflows.find((w) => w.offer_type === 'perm');
                if (workflow) {
                    this.form.get('workflow_id').patchValue(workflow.id);
                }
            }
        }
        console.log('workflow', this.form.value.workflow_id);
        this.view = 'default';
    }

    onChangeWorkSchedule(event) {
        const form = this.form.get('details') as FormGroup;
        if (event.value === 'part') {
            const days_of_work = this.fb.array([
                this.fb.group({
                    title: 'Mon',
                    value: 'Monday',
                    checked: true
                }),
                this.fb.group({
                    title: 'Tue',
                    value: 'Tuesday',
                    checked: true
                }),
                this.fb.group({
                    title: 'Wed',
                    value: 'Wednesday',
                    checked: true
                }),
                this.fb.group({
                    title: 'Thu',
                    value: 'Thursday',
                    checked: true
                }),
                this.fb.group({
                    title: 'Fri',
                    value: 'Friday',
                    checked: true
                }),
                this.fb.group({
                    title: 'Sat',
                    value: 'Saturday',
                    checked: false
                }),
                this.fb.group({
                    title: 'Sun',
                    value: 'Sunday',
                    checked: false
                })
            ]);
            form.addControl('days_of_work', days_of_work);
            form.addControl('include_lunch_break', this.fb.control(true));
            form.addControl('start_time', this.fb.control('08:00', Validators.required));
            form.addControl('end_time', this.fb.control('17:00', Validators.required));
        } else {
            form.removeControl('days_of_work');
            form.removeControl('include_lunch_break');
            form.removeControl('start_time');
            form.removeControl('end_time');
        }
    }

    onChangeOfferType(event) {
        const form = this.form.get('details') as FormGroup;
        if (event.value === 'permanent') {
            form.addControl('original_appointment', this.fb.control(true));
            this.onChangeOriginalAppointment({ value: true });
        } else {
            form.removeControl('original_appointment');
            this.onChangeOriginalAppointment({ value: false });
        }
    }

    onChangeOriginalAppointment(event) {
        if (event.value) {
            this.form.get('original_start_date').setValidators([Validators.required]);
            this.form.get('original_start_date').updateValueAndValidity();
        } else {
            this.form.get('original_start_date').clearValidators();
            this.form.get('original_start_date').updateValueAndValidity();
        }
    }

    onHideOfferPreview() {
        this.previewContractLink = null;
    }

    ngOnDestroy(): void {
        if (this.usersSubscription) {
            this.usersSubscription.unsubscribe();
        }
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }
}
