import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { TenantService } from '@app/core/services/tenant.service';
import { currencyOptions, ftsReasonsCfao, salaryOptions } from '@app/core/constants/options.constants';
import { SelectItem } from 'primeng';
import { FormHelperService } from '@app/core/services/form-helper.service';
import * as moment from 'moment';
import { EmployeesService } from '@app/core/services/employees.service';
import { CandidateService, UtilitiesService } from '@app/core/services';
import { Candidate, Job, User } from '@app/core/models';
import { select, Store } from '@ngrx/store';
import * as fromStore from '@app/store';
import * as fromSelectors from './../../../../../../store/selectors';
import { Subscription, forkJoin } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import {
    CompanyRule,
    Department,
    Division,
    ESS_Levels,
    NatureOfContract,
    PaymentRunDefs,
    Paypoint,
    leavePolicies
} from '@app/core/constants/cfao-offer.constants';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-employee-offer-letter-cfao',
    templateUrl: './employee-offer-letter-cfao.component.html',
    styleUrls: ['./employee-offer-letter-cfao.component.scss']
})
export class EmployeeOfferLetterComponentCfao implements OnInit {
    @Input() candidate: Candidate;
    @Input() workflow: any[];
    @Input() workflows: any[];
    @Input() employees: any[];
    @Input() internalDomains: string[] = [];
    @Output() close = new EventEmitter<boolean>();
    @Output() approval = new EventEmitter();
    contentLoading = false;
    form: FormGroup;
    jobLocationOptions: any[] = [];
    contactsOptions: any[] = [];
    usersOptions: SelectItem[] = [];
    hrBusinessOptions: SelectItem[] = [];
    payrollBusinessPartnerOptions: SelectItem[] = [];
    timeOffOptions: any[] = [
        { value: 17, label: '17' },
        { value: 18, label: '18' },
        { value: 19, label: '19' },
        { value: 20, label: '20' },
        { value: 21, label: '21' },
        { value: 22, label: '22' },
        { value: 23, label: '23' },
        { value: 24, label: '24' },
        { value: 25, label: '25' },
        { value: 26, label: '26' },
        { value: 27, label: '27' },
        { value: 28, label: '28' },
        { value: 29, label: '29' },
        { value: 30, label: '30' }
    ];
    jobTitleOptions: any[] = [];
    payScheduleOptions = [
        { value: 'hourly', label: 'per hour' },
        { value: 'daily', label: 'per day' },
        { value: 'weekly', label: 'per week' },
        { value: 'monthly', label: 'per month' },
        { value: 'yearly', label: 'per year' }
    ];
    comissionTypeOptions = [
        { value: 'fixed', label: 'Value' },
        { value: 'percentage', label: 'Percentage' }
    ];
    bonusTypeOptions = [
        { label: '13th Cheque', value: '13th cheque', selected: false },
        { label: 'Performance Bonus', value: 'performance bonus', selected: false }
    ];
    allowancesTypeOptions = [
        { label: 'Mobile Phone', value: 'mobile phone', selected: false },
        { label: 'Petrol', value: 'petrol', selected: false },
        { label: 'Motor Vehicle Allowance', value: 'motor vehicle allowance', selected: false },
        { label: 'Funeral Cover', value: 'funeral cover', selected: false }
    ];
    probationTypeOptions = [
        { label: 'None (Default)', value: 'none' },
        { label: '30 days', value: 30 },
        { label: '60 days', value: 60 },
        { label: '90 days', value: 90 },
        { label: '180 days', value: 180 }
    ];
    startTimeOptions = [
        { value: '06:00', label: '06:00' },
        { value: '06:30', label: '06:30' },
        { value: '06:45', label: '06:45' },
        { value: '07:00', label: '07:00' },
        { value: '07:15', label: '07:15' },
        { value: '07:30', label: '07:30' },
        { value: '07:45', label: '07:45' },
        { value: '08:00', label: '08:00' }
    ];
    endTimeOptions = [
        { value: '16:00', label: '16:00' },
        { value: '16:30', label: '16:30' },
        { value: '16:45', label: '16:45' },
        { value: '17:00', label: '17:00' },
        { value: '17:15', label: '17:15' },
        { value: '17:30', label: '17:30' },
        { value: '18:00', label: '18:00' }
    ];
    lunchBreakOptions = [
        { value: '30 minutes', label: '30 minutes' },
        { value: '60 minutes', label: '60 minutes' }
    ];
    currencyOptions: SelectItem[] = currencyOptions;
    salaryOptions: SelectItem[] = salaryOptions;
    companyRuleOptions: SelectItem[] = [];
    paymentRunDefIDOptions: SelectItem[] = [];
    leavePolicyIDOptions: SelectItem[] = [];
    natureOfContractOptions: SelectItem[] = [];
    payPointOptions: SelectItem[] = [];
    departmentOptions: SelectItem[] = [];
    divisionOptions: SelectItem[] = [];
    essLevelOptions: SelectItem[] = [];
    view = 'details';
    usersSubscription: Subscription;
    users: User[];
    dropdownValueUpdated = false;
    previewData = {
        start_date: '',
        end_date: '',
        location_short: ''
    };
    minEndDate = moment().toDate();
    minOriginalDate = moment().toDate();
    previewContractLink: string = '';
    ftsReasonsArray: FormArray = this.fb.array([]);
    fts_reasons_extr = ftsReasonsCfao;
    files: {
        job_description_file: File[];
    } = {
        job_description_file: []
    };
    supportedFileTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    constructor(
        private employeeService: EmployeesService,
        private candidateService: CandidateService,
        private formHelper: FormHelperService,
        private fb: FormBuilder,
        private tenantService: TenantService,
        private cdr: ChangeDetectorRef,
        private store: Store<fromStore.State>,
        private toaster: ToastrService,
        private utilitiesService: UtilitiesService
    ) {
        ftsReasonsCfao.forEach((r: string) => {
            let disabled = false;
            this.ftsReasonsArray.push(
                this.fb.group({
                    title: r,
                    checked: true,
                    disabled
                })
            );
        });
        this.ftsReasonsArray.push(
            this.fb.group({
                title: '',
                checked: false
            })
        );
        this.companyRuleOptions = CompanyRule.map((c) => {
            return {
                value: c.CompanyRuleCode,
                label: c['Company Rule Description']
            };
        });
        this.paymentRunDefIDOptions = PaymentRunDefs.map((c) => {
            return {
                value: c['Payment Run Definition Id'],
                label: c['Long Description']
            };
        });
        this.leavePolicyIDOptions = leavePolicies.map((c) => {
            return {
                value: c['Leave Policy Id'],
                label: c['Long Description']
            };
        });
        this.natureOfContractOptions = NatureOfContract.map((c) => {
            return {
                value: c['Code'],
                label: c['Short Description']
            };
        });
        this.payPointOptions = Paypoint.map((c) => {
            return {
                value: c['Hierarchy Code'],
                label: c['Hierarchy Name']
            };
        });
        this.departmentOptions = Department.map((c) => {
            return {
                value: c['Hierarchy Code'],
                label: c['Hierarchy Name']
            };
        });
        this.divisionOptions = Division.map((c) => {
            return {
                value: c['Hierarchy Code'],
                label: c['Hierarchy Name']
            };
        });
        this.essLevelOptions = ESS_Levels.map((c) => {
            return {
                value: c['Hierarchy Code'],
                label: c['Hierarchy Name']
            };
        });
    }

    async ngOnInit() {
        forkJoin([this.tenantService.locations(), this.tenantService.getJobTitles().pipe(take(1))]).subscribe(
            (response: any) => {
                console.log('response', response);
                let [locations, jobTitles] = response;
                locations = locations.filter((l) => l.name !== 'Any of the Dimension Data Offices');
                locations.forEach((l) => {
                    this.jobLocationOptions.push({
                        label: `${l.name} - ${l.location_short}`,
                        value: l.id,
                        short_label: l.location_short,
                        currency: l.currency,
                        name: l.name,
                        hiring_executive: l.hiring_executive
                    });
                });
                jobTitles.forEach((j) => {
                    this.jobTitleOptions.push({
                        value: j.title,
                        label: j.title,
                        ...j
                    });
                });
                this.dropdownValueUpdated = true;
                setTimeout(() => {
                    this.dropdownValueUpdated = false;
                }, 100);
                this.cdr.markForCheck();
            }
        );
        this.usersSubscription = this.store.pipe(select(fromSelectors.getUsersEntities)).subscribe((users: User[]) => {
            this.users = [...users];
            // console.log(users, this.employees);
            this.users.sort((a: any, b: any) => {
                if (a.first_name && b.first_name) {
                    const labelA = a.first_name.toUpperCase();
                    const labelB = b.first_name.toUpperCase();
                    return labelA.localeCompare(labelB);
                }
            });
            this.contactsOptions = this.users
                .filter(
                    (u) => this.internalDomains.length === 0 || this.internalDomains.some((d) => u.email.includes(d))
                )
                .filter((u) => u.role === 'employee' && u.email !== this.candidate.email)
                .map((u) => {
                    return {
                        label: `${u.first_name} ${u.last_name}`,
                        value: u.id,
                        email: u.email
                    };
                });
            this.usersOptions = this.users
                .filter(
                    (u) => this.internalDomains.length === 0 || this.internalDomains.some((d) => u.email.includes(d))
                )
                .filter((u) => u.email !== this.candidate.email)
                .map((u) => {
                    return {
                        label: `${u.first_name} ${u.last_name}`,
                        value: u.id
                    };
                });
            this.hrBusinessOptions = this.users
                .filter((u) => u.role === 'hr_business_partner' && u.email !== this.candidate.email)
                .map((u) => {
                    return {
                        label: `${u.first_name} ${u.last_name}`,
                        value: u.id
                    };
                });
            this.payrollBusinessPartnerOptions = this.users
                .filter((u) => u.role === 'payroll_business_partner' && u.email !== this.candidate.email)
                .map((u) => {
                    return {
                        label: `${u.first_name} ${u.last_name}`,
                        value: u.id
                    };
                });
        });
        this.form = this.fb.group({
            full_name: [
                `${this.candidate.first_name} ${this.candidate.last_name}`,
                [Validators.required, Validators.pattern('\\b\\w+\\b(?:.*?\\b\\w+\\b){1}')]
            ],
            identity_number: ['', Validators.required],
            designation: ['', Validators.required],
            start_date: ['', Validators.required],
            end_date: [''],
            location: ['', Validators.required],
            employee_id: ['', Validators.required],
            manager: ['', Validators.required],
            hiring_executive: ['', Validators.required],
            hr_business_partner: ['', Validators.required],
            payroll_business_partner: ['', Validators.required],
            payout_frequency: [],
            bonuses: this.fb.array([]),
            allowances: this.fb.array([]),
            salary: this.fb.group({
                end_date: [moment().format('DD-MM-YYYY')],
                currency: ['ZAR', Validators.required],
                period: [''],
                salary: [null, Validators.required],
                variable_pay: [false],
                commission_amount: [null]
            }),
            workflow_id: [''],
            ftc_reasons: this.ftsReasonsArray,
            details: this.fb.group({
                offer_type: [],
                send_onboarding_workflow: [true],
                job_description_file: []
            }),
            remuneration: [''],
            termination: [''],
            work_hours: [''],
            time_off: [''],
            hours_of_work: [''],
            payroll_settings: this.fb.group({
                company_rule: ['', Validators.required],
                payment_run_def: ['', Validators.required],
                leave_policy: ['', Validators.required],
                nature_of_contract: ['', Validators.required],
                pay_point: ['', Validators.required],
                department: ['', Validators.required],
                division: ['', Validators.required],
                ess_level: ['', Validators.required]
            })
        });
        this.form.get('details.offer_type').valueChanges.subscribe((value) => {
            if (value === 'permanent') {
                this.form.get('salary.period').clearValidators();
                this.form.get('salary.period').updateValueAndValidity();
            } else if (value === 'fixed') {
                this.form.get('salary.period').setValidators([Validators.required]);
                this.form.get('salary.period').updateValueAndValidity();
            }
        });
        this.form.get('location').valueChanges.subscribe((value) => {
            if (value) {
                const loc = this.jobLocationOptions.find((j) => j.value === value);
                this.form.get('salary.currency').patchValue(loc ? loc.currency : 'ZAR');
            }
        });
        this.form.get('start_date').valueChanges.subscribe((value) => {
            if (value) {
                this.minEndDate = value;
                if (
                    this.form.get('end_date').value &&
                    moment(value).unix() > moment(this.form.get('end_date').value).unix()
                ) {
                    this.form.get('end_date').patchValue(null);
                }
            }
        });
        this.form.get('bonuses').valueChanges.subscribe((changes) => {
            this.bonusTypeOptions.forEach((o) => (o.selected = false));
            changes.forEach((c) => {
                const option = this.bonusTypeOptions.find((a) => a.value === c.type);
                if (option) {
                    option.selected = true;
                }
            });
        });
        this.form.get('allowances').valueChanges.subscribe((changes) => {
            this.allowancesTypeOptions.forEach((o) => (o.selected = false));
            changes.forEach((c) => {
                const option = this.allowancesTypeOptions.find((a) => a.value === c.type);
                if (option) {
                    option.selected = true;
                }
            });
        });
        if (this.candidate.personal && this.candidate.personal.identity_number) {
            this.form.get('identity_number').patchValue(this.candidate.personal.identity_number);
        } else {
            const criminalAss: any = await this.candidateService.getCriminalCheckAss(this.candidate.id);
            if (criminalAss && criminalAss.data && criminalAss.data.id) {
                this.form.get('identity_number').patchValue(criminalAss.data.id);
            }
        }
        if (this.candidate.start_date) {
            this.form.get('start_date').patchValue(moment(this.candidate.start_date, 'DD-MM-YYYY').format());
        }
        // if (this.candidate.job_location) {
        //     this.form.get('location').patchValue(this.candidate.job_location);
        //     const loc = this.jobLocationOptions.find((j) => j.value === this.candidate.job_location);
        //     this.form.get('salary.currency').patchValue(loc ? loc.currency : 'ZAR');
        // }
        if (this.candidate?.salary?.length) {
            const salary = this.candidate.salary[0];
            const form = this.form.get('salary') as FormGroup;
            form.patchValue({
                end_date: salary.end_date,
                currency: salary.currency,
                salary: salary.salary,
                variable_pay: salary.variable_pay || false
            });

            if (salary.variable_pay && salary.commission) {
                const commission = salary.commission;
                let type;
                if (commission.amount) {
                    type = 'fixed';
                } else if (commission.payout_frequency) {
                    type = 'percentage';
                }
            }
        }
        if (this.candidate?.job_details?.reporting?.length) {
            const reporting = this.candidate.job_details.reporting;
            const manager = reporting.find((r) => r.relationship === 'manager');
            const hr_business_partner = reporting.find((r) => r.relationship === 'hr_business_partner');
            if (hr_business_partner) {
                this.form.get('hr_business_partner').patchValue(hr_business_partner.id);
            }
            if (manager) {
                this.form.get('manager').patchValue(manager.id);
            }
        }

        if (this.candidate.employee_id) {
            this.form.get('employee_id').patchValue(this.candidate.employee_id);
        }
    }

    onBack() {
        console.log('back', this.view);
        if (this.view === 'compensation') {
            this.view = 'default';
        } else if (this.view === 'preview') {
            this.view = 'payroll-settings';
        } else if (this.view === 'default') {
            this.view = 'details';
        } else if (this.view === 'options') {
            this.view = 'default';
        } else if (this.view === 'payroll-settings') {
            this.view = 'compensation';
        }
    }

    onPreview() {
        // console.log('onPreview', this.form.value);
        if (!this.form.valid) {
            this.formHelper.markFormGroupTouched(this.form);
            this.cdr.detectChanges();
            return;
        }
        this.previewData.start_date = this.form.value.start_date;
        this.previewData.end_date = this.form.value.end_date;
        const location = this.jobLocationOptions.find((l) => l.value === this.form.value.location);
        if (location) {
            this.previewData.location_short = location.short_label;
        }
        this.view = 'preview';
        this.previewContractLink = '';
    }

    onNext() {
        console.log('onNext', this.form.value, this.form);
        const fields = [
            'full_name',
            'identity_number',
            'designation',
            'start_date',
            'end_date',
            'location',
            'hiring_executive',
            'manager',
            'hr_business_partner',
            'employee_id'
        ];

        for (let field of fields) {
            if (!this.form.get(field).valid) {
                const group = this.form.get(field) as FormGroup;
                group.markAsTouched();
                return;
            }
        }
        if (
            this.form.value.manager === this.form.value.hr_business_partner ||
            this.form.value.hiring_executive === this.form.value.hr_business_partner
        ) {
            this.toaster.error('Signers with the same email address are not allowed');
            return;
        }
        this.view = this.form.value.details.offer_type === 'fixed' ? 'options' : 'compensation';
        if (this.form.value.details.offer_type === 'fixed') {
            (this.form.get('allowances') as FormArray).clear();
            (this.form.get('bonuses') as FormArray).clear();
        } else {
            if (this.candidate.allowances && this.candidate.allowances.length) {
                this.candidate.allowances.forEach((item) => {
                    (this.form.get('allowances') as FormArray).push(
                        this.fb.group({
                            amount: [item.amount, item.type !== 'funeral cover' ? Validators.required : ''],
                            type: [item.type, Validators.required]
                        })
                    );
                });
            }
            if (this.candidate.bonuses && this.candidate.bonuses.length) {
                this.candidate.bonuses.forEach((item) => {
                    (this.form.get('bonuses') as FormArray).push(
                        this.fb.group({
                            amount: [item.amount, Validators.required],
                            type: [item.type, Validators.required]
                        })
                    );
                });
            }
        }
    }

    onPayroll() {
        if (!this.form.get('salary').valid) {
            this.formHelper.markFormGroupTouched(this.form.get('salary') as FormGroup);
            this.cdr.detectChanges();
            return;
        }
        this.view = 'payroll-settings';
    }

    onCompensation() {
        // console.log('compensation', this.view);
        this.view = 'compensation';
    }

    onSendOffer() {
        console.log('onSendOffer', this.form.valid, this.form, this.form.value);
        if (!this.form.valid) {
            this.formHelper.markFormGroupTouched(this.form);
            return;
        }
        this.contentLoading = true;
        const data: any = this.prepareFormBeforeSend();
        console.log('Data', data);
        this.employeeService.employeeOfferApproval(this.candidate.id, data).subscribe(
            (response: any) => {
                console.log('employeeOfferApproval response', response);
                this.approval.emit({ candidateId: this.candidate.id });
                this.contentLoading = false;
                this.cdr.detectChanges();
            },
            (err) => {
                console.log(err);
                this.toaster.error(err.error.error);
                this.contentLoading = false;
                this.cdr.detectChanges();
            }
        );
    }

    prepareFormBeforeSend() {
        const data = { ...this.form.value };
        data.start_date = moment(this.form.value.start_date).format('DD-MM-YYYY');
        data.status = 'pending';
        data.ftc_reasons = this.form.value.ftc_reasons
            .filter((f) => f.checked && f.title)
            .map((f, index) => {
                return { content: f.title, index: index + 1 };
            });
        if (this.form.value.details && this.form.value.details.days_of_work) {
            data.details.days_of_work = this.form.value.details.days_of_work
                .filter((f) => f.checked)
                .map((f) => {
                    return { content: f.value };
                });
        }
        if (this.form.value.end_date) {
            data.end_date = moment(this.form.value.end_date).format('DD-MM-YYYY');
        }
        return data;
    }

    onChangeJobTitle(event) {
        console.log('onChangeJobTitle', event);
        let hours_of_work = '';
        if (event.shift === 'Hours clause Shifts') {
            hours_of_work = 'shift';
        } else if (event.shift === 'Hours clause DP') {
            hours_of_work = 'DP';
        } else {
            hours_of_work = 'generic';
        }
        this.form.patchValue({
            remuneration: event.remuneration,
            termination: event.termination,
            work_hours: event.work_hours,
            time_off: event.time_off,
            hours_of_work
        });
        if (hours_of_work !== 'DP' && hours_of_work !== 'shift') {
            this.form.addControl('start_time', this.fb.control('08:00', Validators.required));
            this.form.addControl('end_time', this.fb.control('17:00', Validators.required));
            this.form.addControl('lunch_break', this.fb.control('30 minutes', Validators.required));
        } else {
            this.form.removeControl('start_time');
            this.form.removeControl('end_time');
            this.form.removeControl('lunch_break');
        }
        if (this.form.get('probation')) {
            this.form.get('probation').patchValue(event.probation);
        }
        this.dropdownValueUpdated = true;
        setTimeout(() => {
            this.dropdownValueUpdated = false;
        }, 100);
    }

    onPreviewContract() {
        const data = this.prepareFormBeforeSend();
        this.contentLoading = true;
        console.log(data);
        this.employeeService.employeeOfferAccepted(this.candidate.id, data, 'preview').subscribe(
            (res: any) => {
                console.log('employeeOfferAccepted', res);
                this.previewContractLink = res.previewContractLink;
                this.contentLoading = false;
                this.cdr.detectChanges();
            },
            (err) => {
                console.log(err);
                this.toaster.error('Preview Contract Error');
                this.contentLoading = false;
            }
        );
    }

    onAddBonusSection() {
        const form = this.form.get('bonuses');
        if (!form.valid) {
            form['controls'].forEach((fg: FormGroup) => {
                if (!fg.valid) {
                    this.formHelper.markFormGroupTouched(fg);
                }
            });
            return;
        }
        (form as FormArray).push(
            this.fb.group({
                amount: [null, Validators.required],
                type: ['', Validators.required]
            })
        );
    }

    onDeleteBonus(i) {
        const form = this.form.get('bonuses');
        (form as FormArray).removeAt(i);
    }

    onAddAllowanceSection() {
        const form = this.form.get('allowances');
        if (!form.valid) {
            form['controls'].forEach((fg: FormGroup) => {
                if (!fg.valid) {
                    this.formHelper.markFormGroupTouched(fg);
                }
            });
            return;
        }
        (form as FormArray).push(
            this.fb.group({
                amount: [null, Validators.required],
                type: ['', Validators.required]
            })
        );
    }

    onDeleteAllowance(i) {
        const form = this.form.get('allowances');
        (form as FormArray).removeAt(i);
    }

    onHideOfferModal() {
        this.close.emit();
    }

    onChangeAllowancesTypeOptions(i) {
        const form = this.form.get('allowances')['controls'][i];
        if (form.value.type === 'funeral cover') {
            form.get('amount').clearValidators();
            form.get('amount').updateValueAndValidity();
            form.get('amount').patchValue(null);
        } else {
            form.get('amount').setValidators([Validators.required]);
            form.get('amount').updateValueAndValidity();
        }
    }

    getUserName(id) {
        const user = this.users.find((u) => u.id === id);
        if (user) {
            return user.full_name;
        } else {
            return id;
        }
    }

    onHideContent(event) {
        (event.target as HTMLInputElement).closest('.preview-block').children[1].classList.toggle('hide');
    }

    onAddDetails() {
        console.log(this.form.value, this.form.valid, this.form);
        if (!this.form.get('details').valid) {
            return;
        }
        if (this.form.value.details.start_time && this.form.value.details.end_time) {
            const start = moment(this.form.value.details.start_time, 'HH:mm').unix();
            const end = moment(this.form.value.details.end_time, 'HH:mm').unix();
            if (start > end) {
                this.form
                    .get('details')
                    .get('end_time')
                    .patchValue(null);
                this.form
                    .get('details')
                    .get('end_time')
                    .markAsTouched();
                return;
            }
        }
        const offer_type = this.form.value.details.offer_type;
        if (offer_type === 'fixed') {
            const workflow = this.workflows.find((w) => w.offer_type === 'fixed');
            if (workflow) {
                this.form.get('workflow_id').patchValue(workflow.id);
            }
        } else if (offer_type === 'permanent') {
            const workflow = this.workflows.find((w) => w.offer_type === 'perm');
            if (workflow) {
                this.form.get('workflow_id').patchValue(workflow.id);
            }
        } else if (offer_type === 'work-based') {
            const workflow = this.workflows.find((w) => w.offer_type === 'work-based');
            if (workflow) {
                this.form.get('workflow_id').patchValue(workflow.id);
            }
        }
        this.view = 'default';
    }

    onChangeOfferType(event) {
        const form = this.form.get('details') as FormGroup;
        if (event.value === 'fixed' || event.value === 'work-based') {
            this.form.get('end_date').setValidators([Validators.required]);
            this.form.get('end_date').updateValueAndValidity();
        } else {
            this.form.get('end_date').clearValidators();
            this.form.get('end_date').updateValueAndValidity();
        }
        if (event.value === 'fixed') {
            this.form.removeControl('probation');
            (this.form.get('details') as FormGroup).addControl('company_provident_fund', this.fb.control(true));
            (this.form.get('details') as FormGroup).removeControl('vehicle_qualification');
        } else {
            this.form.addControl('probation', this.fb.control('none', Validators.required));
            (this.form.get('details') as FormGroup).removeControl('company_provident_fund');
            (this.form.get('details') as FormGroup).addControl(
                'vehicle_qualification',
                this.fb.control('', Validators.required)
            );
        }
        if (event.value === 'work-based') {
            (this.form.get('details') as FormGroup).removeControl('vehicle_qualification');
        }
    }

    onChangeLocation(event: any) {
        // console.log('onChangeLocation', event);
        //General Manager
        // const employee = this.employees.find(
        //     (e) =>
        //         (e.job_location === event.name || e.job_location === event.location) &&
        //         e.job_details &&
        //         e.job_details.designation &&
        //         e.job_details.designation.toUpperCase() === 'GENERAL MANAGER'
        // );
        // console.log(employee);
        // if (!employee) {
        //     return;
        // }
        const contact = this.contactsOptions.find((c) => c.value === event.hiring_executive);
        if (contact) {
            this.form.get('hiring_executive').patchValue(contact.value);
            this.dropdownValueUpdated = true;
            setTimeout(() => {
                this.dropdownValueUpdated = false;
            }, 100);
        }
    }

    onChangeExecutive(event) {
        if (this.form.value.designation === 'General Manager') {
            this.form.get('manager').patchValue(event.value);
            this.dropdownValueUpdated = true;
            setTimeout(() => {
                this.dropdownValueUpdated = false;
            }, 100);
        }
    }

    onHideOfferPreview() {
        this.previewContractLink = '';
    }

    get payroll() {
        return (this.form.get('payroll_settings') as FormGroup).controls;
    }

    private validateFileType(file: File, types: string[]) {
        return types.indexOf(file.type) !== -1;
    }

    startUpload(target: any, type: string, control) {
        const files = type === 'change' ? target.files : target;
        if (
            this.validateFileType(files[0], this.supportedFileTypes) &&
            this.utilitiesService.isFileSizeValid(files[0].size)
        ) {
            for (let i = 0; i < files.length; i++) {
                this.files[control].push(files.item(i));
            }
        } else {
            console.log(
                'invalid format or size',
                this.validateFileType(files[0], this.supportedFileTypes),
                this.utilitiesService.isFileSizeValid(files[0].size)
            );
            this.form
                .get('details')
                .get(control)
                .setErrors({ incorrect: true });
        }
    }

    fileUploaded($event, control) {
        this.files[control] = [];
        this.form
            .get('details')
            .get(control)
            .patchValue($event);
    }

    fileUploadedError(control) {
        this.files[control] = [];
        this.form
            .get('details')
            .get(control)
            .setErrors({ download: true });
    }

    onChangeCompanyRule(event) {
        const company = CompanyRule.find((c) => event.value === c.CompanyRuleCode);
        this.form.get('payroll_settings').patchValue({
            leave_policy: company.LeavePolicyID,
            payment_run_def: company['PaymentRunDefID ACB']
        });
        this.dropdownValueUpdated = true;
        setTimeout(() => {
            this.dropdownValueUpdated = false;
        }, 100);
    }
}
